@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../shared/assets/styles/documents';

.images__container {
  background-color: #ffffff;
  width: 95%;
  min-height: 610px;
}

.images__title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #323232;
  margin-bottom: 16px;
}

.images__secondary-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5c5c5c;
  padding-bottom: 10px;
}

.images__create {
  padding: 16px;
}

.images__index {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  padding: 22px 14px;
  justify-content: flex-start;
}

.images__create {
  border-bottom: 1px solid #eaedee;
}
@media (max-width: 1030px) {
  .images__index {
    gap: 17px;
  }
}

@media (max-width: 991px) {
  .images__index {
    padding: 0;
    padding-top: 10px;
    padding-left: 10px;
    gap: 10px;
  }
}
