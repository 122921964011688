@import "app/frontend/stylesheets/_user-variables.scss";

.timezone-selector {
  display: flex;
  width: 420px;
  justify-content: space-between;

  .selector {
    position: relative;
    width: 240px;
    height: 32px;
    text-align: left;
    outline: none;
    line-height: 47px;
    background-color: #f9f9f9;
    border-radius: 4px;
    border: none;
    margin-right: 10px;

    @media (max-width: 767.98px) {
      width: 100%;
    }
  }

  .selected {
    width: 100%;
    position: absolute;
    padding: 7px 16px;
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #323232;
    cursor: pointer;

    &:after {
      position: absolute;
      content: '';
      top: 11px;
      right: 20px;
      border: solid $quick-silver;
      border-width: 0 2px 2px 0;
      border-radius: 2px;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
    }
  }

  .dropdown .active {
    background: #f9f9f9;
    border-radius: 4px 4px 0 0;
  }

  .items {
    width: 100%;
    position: absolute;
    left: 0;
    top: 32px;
    background: #f9f9f9;
    box-shadow: 0 4px 4px rgba(98, 98, 101, 0.4);
    border-radius: 0 0 4px 4px;
    z-index: 10;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 14rem;
  }

  .items::-webkit-scrollbar {
    width: 0.188rem;
    height: 5.125rem;
  }

  .items::-webkit-scrollbar-thumb {
    background: $quick-silver;
    border-radius: 0.25rem;
  }

  .items div {
    padding: 7px 16px;
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #323232;
    cursor: pointer;
  }

  .items div:hover {
    background: #f3f4f5;
    border-radius: 4px;
  }

  .disabled {
    display: none;
  }
}
