@import "app/frontend/stylesheets/_user-variables.scss";

.alert-success {
  border-width: 1px;
  text-align: left !important;
}

.alert-error {
  border-width: 1px;
  text-align: left !important;
}

.alert .close {
  padding-right: 0;
}

.alert-dismissible {
  width: 500px;
  height: auto;
  z-index: 9999;
  border-radius: 8px !important;
  font-size: 14px !important;
}

.alert {
  position: fixed !important;
}

.watch {
  &.alert {
    right: 50%;
    margin-right: -680px;
    top: 87px;

    @media (max-width: 1440px) {
      &.alert {
        right: 40px;
        margin-right: 0;
      }
    }
  }

  &.alert-success {
    border-color: #21a18a;
    color: $white;
    background-color: #204f46;
  }
}

.profile {
  &.alert {
    top: 85px;
  }

  &.alert-success {
    border-color: #aae9dd;
    color: #21a18a;
    background-color: #f2fffd;
  }

  &.alert-error {
    border-color: #e9a8b1;
    color: #d35163;
    background-color: #ffe8eb;
  }

  &.alert-dismissible {
    right: 48px;
  }
}

.admin {
  &.alert .close {
    margin-left: auto;
    padding: 0;
    margin-bottom: 0;
    background: transparent;
    border: none;
    box-shadow: none;
  }

  &.alert {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    right: 4%;
  }
}
