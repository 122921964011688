@import "app/frontend/stylesheets/_user-variables.scss";

.store-items__button-section {
  position: relative;
}

.store-items__button-tooltip {
  position: absolute;
  width: 177px;
  height: auto;
  padding: 12px;
  top: -128px;
  left: 0;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 4px 30px 0 #62626566;

  input {
    height: 32px;
    padding: 12px 8px 12px 8px;
    border-radius: 2px;
    border: 1px solid #d1d3d4;
    font-family: DM Sans, serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #323232;
  }

  .counter {
    font-family: DM Sans, serif;
    font-size: 8px;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
    color: #a6a6a6;
    display: flex;
    justify-content: end;
  }
}

.store-items__store-button {
  height: 32px;
  top: 629px;
  left: 253px;
  padding: 6px 12px 6px 12px;
  gap: 24px;
  border-radius: 4px;
  background-color: #5c5c5c;
  cursor: pointer;
  color: white;
  font-family: DM Sans, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}
