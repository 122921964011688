@import 'vendor/flickity/flickity';
@import 'vendor/flickity-fade/flickity-fade';

/* do not allow to click trough disabled buttons */
.flickity-button:disabled {
  pointer-events: auto;
}

/*
  We need to decrease opacity for disabled buttons manually.
  Prev/next buttons stay in the focus on mobile devices after click on them
  even if a button is disabled thus they are rendered in the full opacity.
 */
.flickity-button:disabled:hover,
.flickity-button:disabled:focus,
.flickity-button:disabled:active {
  opacity: 0.3;
}

.flickity-page-dots .dot {
  border-radius: 0.5rem; /* 8px */
}

.flickity-page-dots .dot.is-selected {
  border: 2px solid #fff;
  box-sizing: border-box;
}
