@import "app/frontend/stylesheets/_user-variables.scss";

.modal-b.dark {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 25, 35, 0.8);

  .modal-c {
    display: flex;
    flex-direction: column;
    margin: 20% auto;
    padding: 32px;
    width: 416px;
    height: 146px;
    background: #32303c;
    box-shadow: 0 4px 30px rgba(23, 24, 28, 0.6);
    border-radius: 8px;
  }

  .button {
    border-radius: 4px;
    border: none;
    font-size: 14px;
    height: 32px;
    width: 80px;
    color: $white;
    justify-content: center;
    display: flex;
    padding: 4px 0;
  }

  .delete {
    @extend .button;
    background-color: #6998ae;
    margin: 0 8px;
    cursor: pointer;
  }

  .cancel {
    @extend .button;
    background-color: #444050;
    cursor: pointer;
  }

  .modal-text {
    color: $white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 24px;
  }
}

.light.modal-b {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 25, 35, 0.8);

  .modal-c {
    display: flex;
    flex-direction: column;
    margin: 20% auto;
    padding: 32px;
    width: 416px;
    height: 146px;
    background: $white;
    box-shadow: 0 4px 30px rgba(23, 24, 28, 0.6);
    border-radius: 8px;
  }

  .button {
    border-radius: 4px;
    border: none;
    font-size: 14px;
    height: 32px;
    width: 80px;
    color: $white;
    justify-content: center;
    display: flex;
    padding: 6px 0;
  }

  .delete {
    @extend .button;
    background-color: #6998ae;
    margin: 0 8px;
    cursor: pointer;
  }

  .cancel {
    @extend .button;
    box-shadow: 0 2px 10px rgba(73, 73, 73, 0.2);
    background-color: $white;
    color: #6998ae;
    cursor: pointer;
  }

  .modal-text {
    color: #323232;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 24px;
    display: flex;
  }
}

svg {
  margin-right: 8px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
}
