@import "app/frontend/stylesheets/_user-variables.scss";

.video-resources__toggle-wrapper {
  width: 100%;
  padding: 0 20px;
  .video-resources__toggle {
    font-size: 18px;
    padding: 15px 20px;
    background: #2b2834;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      margin-right: 15px;
    }
  }
}
.video-resources__header {
  padding: 15px;
  display: flex;
  .video-resources__title {
    justify-self: center;
    margin: auto;
  }
}

.video-resources-mobile__content {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  z-index: 3000;
  top: 0;
  left: 0;
  background: #1c1923;
}
