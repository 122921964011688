@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';

.main-block-wrapper {
  position: relative;
  width: 100%;
  font-family: 'DM Sans', sans-serif;
}

.main-block {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.transcripts__sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 17%;
  padding-right: 16px;
  gap: 8px;
}

.transcripts__container {
  width: 83%;
  display: flex;
  flex-direction: column;
}
.transcripts__tab {
  display: flex;
  a {
    padding: 6px 12px;
    border-radius: 3px;
    color: #323232;
    text-decoration: none;

    &.router-link-active {
      background: #414042;
      color: #ffffff;
    }
  }
}

.transcripts__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #323232;
}
