@import "app/frontend/stylesheets/_user-variables.scss";

.search-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  border-bottom: 1px #32303c solid;
  margin-bottom: 32px;

  @media (max-width: 463.98px) {
    overflow: auto;
    height: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 3px;
      height: 2px;
      &:hover {
        width: 10px;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #8f8a9b;
      border-radius: 4px;
      border: 4px solid #8f8a9b;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}

.search-nav__light {
  border-bottom: 1px #eaedee solid;
}
