@import "app/frontend/stylesheets/_user-variables.scss";

.search-loader {
  display: flex;
  width: 40px;
  align-items: center;
  margin: 0 auto;
}

.search-block {
  margin-top: 40px;
}

.search-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--backgrounds-white, #fff);
  font-family: DM Sans, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 32px;
}

.search-info-query {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.search-info__light {
  color: #323232;
}

.search-results {
  display: flex;
  flex-direction: column;
}

.tab-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;

  .name {
    color: var(--backgrounds-white, #fff);
    font-family: DM Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .name__light {
    color: #323232;
  }

  .count {
    color: var(--text-input, #a6a6a6);
    font-family: DM Sans, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .count__light {
    color: #5c5c5c;
  }
}

.results {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.search-loader-data {
  display: flex;
  margin: 0 auto;
  width: 100%;

  img {
    width: 40px;
    height: 40px;
  }
}

.no-results-block {
  width: 100%;
  color: #f3f4f5;
  font-family: DM Sans, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.no-results-block__light {
  color: #5c5c5c;
}
