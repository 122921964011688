@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../shared/assets/styles/global';

.bookmark-order__wrapper {
  color: $white;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  margin: auto 0;
}

.bookmark-order__input {
  display: flex;
  margin-left: auto;
}

.bookmark-order__input,
.bookmark-order__dropdown {
  background-color: #444050;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 200px;
  margin-bottom: 3px;
}

.bookmark-order__dropdown {
  position: absolute;
  z-index: 2;
}

.bookmark-order__input,
.bookmark-order__option {
  padding: 7px 16px;
  cursor: pointer;
}
