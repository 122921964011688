@import "app/frontend/stylesheets/_user-variables.scss";

.article-main {
  max-width: 736px;
  margin: auto;
}

.subject-link {
  font-family: DM Sans, serif;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.16em;
  text-align: left;
  color: #bb711a;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.article-title {
  font-family: DM Sans, serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  color: #323232;
  margin-bottom: 10px;
}

.article__new,
.article_new__mobile {
  background: #d18f42;
  color: #fff;
  border-radius: 0 4px 4px 0;
  padding: 4px 12px;
  font-size: 12px;
  margin-right: 10px;
}

.article_new__mobile {
  display: none;
}

.article-publish-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .publish-info {
    font-family: DM Sans, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #a6a6a6;
    display: flex;
    gap: 5px;
  }

  .print-button {
    display: inline-block;
    background: #6998ae;
    border: none;
    color: #fff;
    margin-left: 10px;
    padding: 9px 16px;
    border-radius: 4px;
    font-family: DM Sans, serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    min-width: 100px;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 2px #444050;
    }
  }
}

.cover-wrapper {
  width: 100%;
  margin-bottom: 20px;

  img {
    width: 100%;
  }
}

.authors-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.authors {
  display: flex;
  font-size: 16px;
  align-items: center;
  .author-img__wrapper {
    margin-left: 4px;
  }
  .author-label,
  .author-name {
    font-family: DM Sans, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: #5c5c5c;
    vertical-align: middle;
  }
  .author-logo {
    width: 32px !important;
    height: 32px !important;
    border: 0.5px solid #d1d3d4;
    border-radius: 50%;

    &:not(:first-child) {
      margin-left: -12px;
    }
  }

  .author-name {
    margin-left: 4px;
    overflow: hidden;
  }
}

.article-description {
  font-family: DM Sans, serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #323232;
  margin-bottom: 20px;

  &::after {
    content: '';
    width: 32px;
    height: 4px;
    background: var(--primary-apricot-default, #d18f42);
    display: flex;
    margin-top: 32px;
  }
}

.article-content {
  margin-bottom: 50px;
}

.topics {
  margin-bottom: 5px;
}

.topic {
  font-family: DM Sans, serif;
  display: inline-block;
  max-width: 229px;
  padding: 4px 10px;
  height: 24px;
  background: #fff;
  border: 1px solid #d1d3d4;
  border-radius: 12px;
  font-size: 12px;
  line-height: 16px;
  color: #5c5c5c;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;
}

.article-share {
  display: flex;
  width: 110px;
  justify-content: space-between;
  margin-left: auto;

  a {
    color: #a6a6a6;
    cursor: pointer;
    svg {
      height: 18px;
    }

    svg.feather-facebook,
    svg.feather-x-twitter,
    svg.feather-linkedin {
      fill: #a6a6a6;
      stroke: none;
    }
  }

  a:hover {
    color: #5c5c5c;

    svg.feather-linkedin {
      fill: #5c5c5c;
      stroke: none;
    }
    svg.feather-facebook,
    svg.feather-x-twitter {
      stroke: #5c5c5c;
    }
  }
}
.copy_current_url-tooltip__container {
  position: relative;

  span {
    position: absolute;
    top: -36px;
    left: -30px;
    font-family: DM Sans, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 6px 12px;
    background: #ffffff;
    box-shadow: 0 2px 15px rgba(73, 73, 73, 0.2);
    border-radius: 4px;
    color: #323232 !important;
  }
}

.engagement-icon {
  fill: #a6a6a6;
}

@media (max-width: 991px) {
  .article-main {
    padding: 0 15px;
  }
  .print-button.print {
    display: none;
  }
  .article__new {
    display: none;
  }
  .article_new__mobile {
    display: inline-block;
    margin-bottom: 10px;
  }
}
