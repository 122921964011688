@import "app/frontend/stylesheets/_user-variables.scss";

svg {
  cursor: pointer;
}
.light {
  svg {
    path {
      fill: #5c5c5c;
    }
  }
}

.dark {
  svg {
    path {
      fill: #8f8a9b;
    }
  }
}
