@import "app/frontend/stylesheets/_user-variables.scss";

.donate__block {
  background: #f3f4f5;
  border-radius: 4px;
  border-left: solid #d18f42 4px;
  font-family: 'DM Sans', sans-serif;
  margin: 12px 0;

  @media (max-width: 991px) {
    border-left: none;
    border-top: solid #d18f42 4px;
  }
}

.donate__block-container {
  max-width: 838px;
  padding: 32px 44px 40px;

  * {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    margin-right: 16px;
    margin-left: 16px;
  }

  @media (max-width: 640px) {
    padding: 24px 8px 20px;
  }
}

.donate__question {
  font-style: normal;
  color: #323232;
  margin-bottom: 8px;
}

.donate__question-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.donate__question-description {
  width: 700px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 24px;
  white-space: pre-line;
}

.donate__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-content: space-between;
}

.donate__button-us,
.donate__button-ca,
.donate__button-explore-projects {
  width: 217px;
  height: 48px;
  cursor: pointer;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0;
  @media (max-width: 991px) {
    width: 100%;
  }
}

.donate__button-us,
.donate__button-ca {
  background: #6998ae;
  box-shadow: 0 2px 15px rgba(73, 73, 73, 0.2);
  color: $white;
  &:hover {
    color: $white;
  }
}

.donate__button-explore-projects {
  background: $white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  color: #6998ae;
}
