@import "app/frontend/stylesheets/_user-variables.scss";

.scroll-top-button-container {
  position: relative;
  display: none;
  z-index: 999;

  .scroll-top-button {
    position: fixed;
    right: 20%;
    bottom: 115px;
    color: #6998ae;
    background: white;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgb(73 73 73 / 15%);
    padding: 10px;
    line-height: 0;
    cursor: pointer;

    svg {
      height: 24px;
      width: 24px;
    }
  }
  &.fixed {
    display: block;
  }
}

@media (max-width: 992px) {
  .scroll-top-button-container {
    display: none !important;
  }
}
