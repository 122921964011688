@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';

.news-container {
  width: 100%;
  min-height: 220px;
  background-color: #ffffff;
  margin-bottom: 16px;
  padding: 16px;
}

.toggle-container {
  margin-top: 12px;
}

.show-more,
.show-less {
  cursor: pointer;
  background-color: $white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: $quick-silver;
}

.news-bookmarks-container {
  padding-left: 24px;
}
.footnote {
  line-height: 14px;
  margin-top: 3px;
  width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  a {
    color: #5c5c5c;
    font-size: 14px;
    text-decoration: none;
  }
}
