@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../../../../../node_modules/vue-multiselect/dist/vue-multiselect.css';

.selector-block {
  margin-left: 8px;
  width: 965px;
}

.custom-multiselect {
  .multiselect__input {
    transition: none !important;
    border: none !important;
  }
  .multiselect__tag {
    background: #e4e4e4 !important;
    color: #333b51cc !important;

    .multiselect__tag-icon::after {
      color: #333b51cc !important;
    }
  }

  .multiselect__content-wrapper {
    max-height: 250px !important;
  }

  .multiselect__option--highlight {
    background: #214166 !important;
  }
}
