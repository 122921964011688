@import "app/frontend/stylesheets/_user-variables.scss";

.bg-blur {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #323232;
  opacity: 0.9;
  z-index: 9999;
}

.disabled-action {
  cursor: not-allowed;
  pointer-events: none;
}

.upload-video-modal {
  position: fixed;
  display: flex;
  top: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 10000;
  flex-direction: column;
  width: 462px;
  min-height: 459px;
  border-radius: 3px;
  box-shadow: 0 8px 16px rgba(35, 35, 35, 0.5);
  background-color: #f7f7f7;
}

.header-section {
  height: 36px;
  padding: 0 8px;
  line-height: 36px;
  background: #214166;
  color: #fff;
  font-weight: normal;
  font-size: 1em;
  margin-top: -2px;

  span {
    display: flex;
    justify-content: left;
    text-align: left;
    font-weight: bold;
    padding: 0 4px;
    margin-right: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-font-smoothing: antialiased;
  }
}

.body-section {
  padding: 32px;

  .input-block {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      width: 100%;
      justify-content: left;
    }

    input {
      display: flex;
      width: 100%;
      justify-content: left;
    }
  }
}

.submit-button {
  display: flex;
  width: 100%;
  height: 36px;
  padding: 0 8px;
  line-height: 36px;
  background: #0058c2;
  color: #fff;
  border-radius: 4px;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}

.upload-block {
  margin-bottom: 8px;

  .file-upload {
    line-height: 36px;
    background: green;
    color: #fff;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
  }

  .file-upload span {
    display: inline-block;
    padding: 0 8px;
    cursor: pointer;
    width: 100%;
  }
}

.custom-file-input {
  position: relative;
  overflow: hidden;
}

.custom-file-input input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.custom-file-label {
  display: inline-block;
  margin-top: 2px;
}

.custom-file-input {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-file {
  width: 100%;
  position: relative;
  display: inline-block;
}

.custom-file-input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.custom-file-label {
  width: 100%;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: break-spaces;
}

.validation-error {
  border-color: red !important;
  margin-bottom: 0 !important;
}

.validation-error__text {
  font-size: 12px;
  color: red;
  display: flex;
  margin-bottom: 8px;
}

.error-block {
  padding: 40px 50px 0 50px;

  @media (max-width: 767.98px) {
    padding: 40px 25px 0 25px;
  }

  .message {
    min-height: 60px;
    height: auto;
    padding: 30px 20px;
    background-color: rgb(255, 214, 201);
    border-radius: 4px;

    font-size: 14px;
    line-height: 18px;
    color: red;
    margin: 0;
  }
}

.uploading-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loader {
    display: flex;
    width: 32px;
    height: 32px;
  }

  .status {
    margin-top: 8px;
    width: 100%;
    margin-bottom: 8px;
  }

  .step {
    width: 100%;
    margin-bottom: 8px;
  }

  .progress {
    width: 100%;
  }
}

.alert {
  margin-top: 16px;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-warning {
  width: 100%;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.close-modal {
  margin-top: 8px;
}
