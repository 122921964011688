@import "app/frontend/stylesheets/_user-variables.scss";

.horizontal-calendar {
  display: flex;
  width: 100%;
  height: 88px;
  margin: 20px 0;
  background: #f3f4f5;
  border-radius: 8px;
  position: relative;
  justify-content: space-between;

  .calendar-days {
    display: flex;
    overflow-x: hidden;
  }
  .calendar-day {
    text-align: center;
    font-weight: 500;
    color: #5c5c5c;
    margin: auto 10px;
    white-space: nowrap;
    padding: 6px 30px;
    border-radius: 8px;
    cursor: pointer;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */

    &.disabled {
      color: #a6a6a6 !important;
      cursor: not-allowed !important;
      pointer-events: none !important;
    }
    .calendar-weekday {
      font-size: 20px;
    }
    .calendar-date {
      font-size: 14px;
    }
  }
  .arrow-left,
  .arrow-right {
    margin: 12px;
    width: 64px;
    height: 64px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 40px rgba(167, 167, 167, 0.15);
    text-align: center;
    padding: 20px 25px;
    color: #131118;

    &.disabled {
      color: #a6a6a6;
      cursor: not-allowed;
    }
  }

  .arrow-left,
  .arrow-right {
    cursor: pointer;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
  }

  .calendar-day__selected {
    background: #6998ae;
    color: #f3f4f5;
  }
}
