@import "app/frontend/stylesheets/_user-variables.scss";

img,
iframe {
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
  border: none;
}

.preview__cover {
  object-fit: cover;
  height: 500px;
}

.preview__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.preview__read-button {
  align-self: center;
  margin-top: -45px;
  display: flex;
  width: 93px;
  height: 32px;
  padding: 9px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #6998ae;
  color: #ffffff;
  box-shadow: 0px 1px 10px 0px rgba(29, 32, 35, 0.15);

  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 15px 0px rgba(29, 32, 35, 0.15);
  }
}

.preview__document-viewer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 991px) {
  .preview__cover {
    height: 60vh;
  }
  .preview__read-button {
    margin-top: 20px;
  }
}
