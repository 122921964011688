@import "app/frontend/stylesheets/_user-variables.scss";

.schedule__title {
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #323232;
}

.schedule__title-wrapper {
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.schedule-controls {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
}

.timezone-selector,
.schedule__buttons-container {
  @media (max-width: 1024px) {
    margin: 0 auto;
  }
}
.import-schedule__wrapper {
  @media (max-width: 768px) {
    display: none;
  }
}
.schedule__buttons-container {
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
}
