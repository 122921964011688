@import "app/frontend/stylesheets/_user-variables.scss";

.link__form-input {
  font-size: 14px;
  line-height: 18px;
  color: #323232;
  width: 100%;

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a6a6a6;
  }
}

.link__counter {
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #a6a6a6;
  font-family: 'DM Sans';
  font-style: normal;
}

.link__form-control {
  display: flex;
  width: 517px;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 6px;
}

.link__form-button {
  padding-top: 8px;
}

.link__button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 3px 50px;
  background-color: #000000;
  color: #ffffff;

  &:disabled {
    background-color: #555555;
  }

  &:hover {
    box-shadow: 0 2px 3px rgba(23, 24, 28, 0.6);
  }
}

.link__link {
  padding-bottom: 8px;
}

.link__delete {
  position: absolute;
  right: -42px;
  top: 5px;
  padding: 12px 0;
  cursor: pointer;
}

.link__delete-icon {
  width: 19px;
  height: 2px;
  background: #5c5c5c;
}

.link__error {
  position: absolute;
  top: 36px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #d35163;
}
