@import "app/frontend/stylesheets/_user-variables.scss";

.store-item-card-block {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 240px;
  flex: 1;

  .thumbnail {
    height: 140px;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;', serif;
    margin-bottom: 8px;
  }
}

.store-item-card-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'DM Sans', sans-serif !important;
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.store-item-card-title__light {
  color: #323232;
}

.card-description {
  margin-top: 4px;
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  font-family: 'DM Sans', sans-serif !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.store-item-card-description__light {
  color: #5c5c5c;
}

.store-item-card-link {
  color: inherit;
  white-space: break-spaces;
  line-height: 0;
}

.no-image {
  object-fit: contain !important;
}
