@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../shared/assets/styles/video_resources';
@import '../../../shared/assets/styles/document';

.document__container {
  width: 100%;
  padding: 0;
}

.document__text {
  width: 65%;
}

.light {
  .document__container {
    border: none;

    &:hover {
      background-color: #ffffff;
    }
  }
}

.dark {
  .document__container {
    border: none;
  }
}

.video-resources_download {
  &:hover {
    text-decoration: none;
  }
}

@media (max-width: 991px) {
  .document__preview-title {
    display: none;
  }
  .document__container.ebook__container {
    display: none;
  }
  .document__description {
    width: 100%;
  }
}
