@import "app/frontend/stylesheets/_user-variables.scss";

.donate-block {
  width: 100%;
  height: 389px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1em 2em 2em 1em;

  p {
    color: #ffffff;
  }
}

.donate-button {
  width: 15em;
}
