@import "app/frontend/stylesheets/_user-variables.scss";

.study-tools-login-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 389px;
  text-align: center;
  width: 100%;

  .content-container {
    width: 100%;
  }

  .form-notes__item {
    color: #D1D3D4;
    font-size: 16px;
    margin-bottom: 0;
  }

  .study-tools-login-heading {
    display: inline-block;
    font-weight: normal;
    width: 100%;
    margin-bottom: 40px;
    font-size: 34px;
    line-height: 1.08;
    color: #ffffff;
  }

  .study-tools-login {
    max-width: 320px;
    margin-bottom: 40px;
  }

  .btn-primary {
    display: flex;
    width: 303px;
    height: 48px;
    padding: 9px 0;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px 0px;
    background: #6998AE;
    box-shadow: 0 2px 15px 0 rgba(38, 42, 46, 0.30);
  }

  .content-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.light {
  &.study-tools-login-block {
    height: 230px;

    .form-notes__item {
      color: #323232;
    }

    .study-tools-login-heading {
      color: #323232;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 24px;
    }

    .study-tools-login {
      max-width: 300px;
      margin-bottom: 24px;
    }
  }
}

