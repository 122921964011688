@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';

.locked {
  pointer-events: none;
  opacity: 0.6;
}

.notes-header {
  font-weight: 500;
  display: flex;
  justify-content: space-between;

  .title-text {
    font-size: 20px;
    margin-right: 4px;
  }

  .counter {
    font-size: 14px;
  }
}
.notes-title {
  margin: auto 0;
}

.note-form {
  font-size: 14px;
}

.notes-back-button__container {
  position: relative;
  width: 100%;

  .bookmark-back-button {
    position: absolute;
    right: -28px;
    top: -98px;
    font-size: 1.125rem;
  }
}

.notes-container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding-inline-start: 0;
  overflow: hidden;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;

    &:hover {
      width: 10px;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}
.new-notes-area,
.notes-title {
  background: transparent;
  border: none;
  resize: none;
  display: flex;
  width: 85%;
  flex-basis: 100%;
  overflow-wrap: anywhere;

  &:focus {
    outline: none;
  }
}
.notes-title-wrapper {
  padding: 12px 16px;
  display: flex;
  align-items: baseline;
}
.new-notes-area {
  height: 32px;

  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.note-actions,
.note-actions form {
  display: flex;
  margin-left: auto;
  justify-content: end;
  width: 205px;
  margin-right: 3px;
}

.note__action-button {
  border-radius: 4px;
  border: none;
  font-size: 14px;
  height: 32px;
  width: 85px;
}
.note__cancel-button {
  @extend .note__action-button;
  margin-left: auto;
}
.note__save-button {
  @extend .note__action-button;
  margin: 0 8px;
}

.tags-select__wrapper {
  position: inherit;
}

.tags-button {
  padding: 3px 12px;
  font-size: 14px;
  border-radius: 25px;
}

.note-tags {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  margin-top: 10px;
  padding: 12px 18px;
}

.note-tags__title {
  font-size: 14px;
  flex-grow: 0.02;
  margin: auto 0;
}

.note-tags__tag {
  display: flex;
  padding: 4px 10px;
  margin: 3px 3px;
  border-radius: 20px;
  font-size: 12px;
  justify-content: space-between;
  flex-grow: 0.02;
}

.note-tags__tag-remove {
  margin: auto 0;
}

.tags-limit-message {
  font-size: 14px;
  line-height: 18px;
  margin: auto 3px;
}

// theme
// LIGHT
//#6998ae;
$tags-limit-color-light: #8f8a9b;
$tag-border-light: #d1d3d4;
$tags-white: #ffffff;
$notes-blue: #6998ae;
$notes-text-light: #323232;
$title-border-light: #eaedee;
$title-hovered-background-light: #f9f9f9;
// DARK
$edit-tags-color-light: #5c5c5c;
$edit-tags-color-dark: #d1d3d4;
$tag-border-dark: #444050;
$tags-limit-color-dark: #8f8a9b;
$tags-button-dark: #3b3946;
$title-hovered-background-dark: #32303c;

.light {
  .notes-header {
    .title-text {
      font-size: 20px;
      color: $notes-text-light;
      margin-right: 4px;
    }

    .counter {
      font-size: 14px;
      color: #a6a6a6;
    }
  }
  .notes-container {
    background-color: $tags-white;
    &::-webkit-scrollbar-thumb {
      background-color: $tags-limit-color-dark;
      border: 4px solid $tags-limit-color-dark;
    }
  }
  .new-notes-area,
  .notes-title {
    color: $notes-text-light;
  }
  .notes-title-wrapper {
    border-bottom: 1px $title-border-light solid;
  }
  .notes-title-wrapper:hover {
    background: $title-hovered-background-light;
  }
  .new-notes-area:before {
    color: $tags-limit-color-dark;
  }
  .note__action-button {
    color: $notes-text-light;
  }
  .note__cancel-button {
    background-color: $tags-white;
    color: $notes-blue;
    box-shadow: 0 1px 10px rgba(29, 32, 35, 0.15);
    border-radius: 4px;
  }
  .note__save-button {
    background-color: #6998ae;
    color: $tags-white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

    &:disabled,
    &[disabled] {
      color: $tags-limit-color-dark;
      background: #f9f9f9;
    }
  }
  .tags-button {
    background-color: $tags-button-dark;
    border: 1px solid $tag-border-dark;
    color: $tags-white;
  }
  .note-tags {
    border-top: 1px $tag-border-dark solid;
  }
  .note-tags__title {
    color: $edit-tags-color-light;
  }

  .note-tags__tag-name {
    color: $edit-tags-color-light;
  }

  .note-tags__tag {
    border: 1px solid $tag-border-light;
  }

  .tags-limit-message {
    color: $edit-tags-color-light;
  }
}

.dark {
  .notes-header {
    .title-text {
      font-size: 20px;
      color: $tags-white;
      margin-right: 4px;
    }

    .counter {
      font-size: 14px;
      color: #a6a6a6;
    }
  }
  .notes-container {
    background-color: #2b2834;
    &::-webkit-scrollbar-thumb {
      background-color: $tags-limit-color-dark;
      border: 4px solid $tags-limit-color-dark;
    }
  }
  .new-notes-area,
  .notes-title {
    color: $tags-white;
  }
  .notes-title-wrapper {
    border-bottom: 1px $tag-border-dark solid;
  }
  .notes-title-wrapper:hover {
    background: $title-hovered-background-dark;
  }
  .new-notes-area:before {
    color: $tags-limit-color-dark;
  }
  .note__action-button {
    color: $tags-white;
  }
  .note__cancel-button {
    background-color: $tag-border-dark;
  }
  .note__save-button {
    background-color: #6998ae;
    &:disabled,
    &[disabled] {
      color: $tags-limit-color-dark;
      background-color: $tags-button-dark;
    }
  }
  .tags-button {
    background-color: $tags-button-dark;
    border: 1px solid $tag-border-dark;
    color: $tags-white;
  }
  .note-tags {
    border-top: 1px $tag-border-dark solid;
  }
  .note-tags__title {
    color: $edit-tags-color-dark;
  }

  .note-tags__tag-name {
    color: $edit-tags-color-dark;
  }

  .note-tags__tag {
    border: 1px solid $tag-border-dark;
  }

  .tags-limit-message {
    color: $tags-limit-color-dark;
  }
}
