/**
 * css for videojs-mobile-ui
 */

@keyframes fadeAndScale {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.video-js {
  &.vjs-has-started .vjs-touch-overlay {
    position: absolute;
    pointer-events: auto;
    top: 0;
  }

  .vjs-touch-overlay {
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &.skip {
      opacity: 0;
      animation: fadeAndScale 0.6s linear;
      background-repeat: no-repeat;
      background-position: 80% center;
      background-size: 10%;
      background-image: url('data:image/svg+xml;utf8,<svg fill="%23FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M4 18l8.5-6L4 6v12zm9-12v12l8.5-6L13 6z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
    }

    &.skip.reverse {
      background-position: 20% center;
      background-image: url('data:image/svg+xml;utf8,<svg fill="%23FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M11 18V6l-8.5 6 8.5 6zm.5-6l8.5 6V6l-8.5 6z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
    }

    .vjs-play-control {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 30%;
      height: 80%;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.3s ease;

      .vjs-icon-placeholder::before {
        content: '';
        background-size: 60%;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml;utf8,<svg fill="%23FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
      }

      &.vjs-paused .vjs-icon-placeholder::before {
        content: '';
        background-image: url('data:image/svg+xml;utf8,<svg fill="%23FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M8 5v14l11-7z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
      }

      &.vjs-ended .vjs-icon-placeholder::before {
        content: '';
        background-image: url('data:image/svg+xml;utf8,<svg fill="%23FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"/></svg>');
      }
    }

    &.show-play-toggle .vjs-play-control {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.vjs-mobile-ui-disable-end.vjs-ended .vjs-touch-overlay {
    display: none;
  }
}
