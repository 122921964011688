@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../shared/assets/styles/video_resources';
@import '../../../shared/assets/styles/document';

.ebook__form-input {
  font-size: 14px;
  line-height: 18px;
  color: #323232;
  width: 100%;

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a6a6a6;
  }
}

.ebook__counter {
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #a6a6a6;
  font-family: 'DM Sans';
  font-style: normal;
}
.ebook__error {
  position: absolute;
  top: 36px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #d35163;
}

.ebook__form-control {
  display: flex;
  gap: 16px;
  flex-direction: row;
  padding-bottom: 16px;

  .drag-drop-area {
    background-image: url("data:image/svg+xml,%3Csvg width='133' height='133' viewBox='0 0 137 137' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='133' height='133' rx='6' stroke='%23A1A1A1' stroke-linecap='round' stroke-dasharray='5 7'/%3E%3C/svg%3E%0A");
    cursor: pointer;
    width: 133px;
    height: 133px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  .file-info__text {
    max-width: 133px;
    max-height: 133px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #a1a1a1;
  }

  .upload-icon {
    width: 22px;
    height: 17px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='17' viewBox='0 0 22 17' fill='none'%3E%3Cpath d='M18.7928 10.625V13.4583C18.7928 13.8341 18.6042 14.1944 18.2686 14.4601C17.933 14.7257 17.4779 14.875 17.0033 14.875H4.47697C4.00238 14.875 3.54722 14.7257 3.21162 14.4601C2.87603 14.1944 2.6875 13.8341 2.6875 13.4583V10.625' stroke='%23A1A1A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.26562 7.08594L10.7393 10.6276L15.213 7.08594' stroke='%23A1A1A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.7344 10.625V2.125' stroke='%23A1A1A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}

.ebook__drag-drop-separator {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5c5c5c;
  align-self: center;
}

.ebook__item-thumbnail {
  width: 64px;
  height: 80px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 4px;
}
.document__file-error.ebook__file-error {
  top: auto;
  width: 140px;
  line-height: 10px;
}

.document__container.ebook__container {
  height: 80px;
  padding: 0 6px 0 0;
  margin-top: 12px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border: 0;
  justify-content: flex-start;
  width: 49%;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.ebook__info {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  height: 80px;
}

.ebook__size {
  padding-top: 26px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #a6a6a6;
}

.ebook__author {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #5c5c5c;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 190px;
}

.ebook__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #323232;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 190px;
}

@media (max-width: 383px) {
  .ebook__title,
  .ebook__author {
    width: 150px;
  }
}

.light {
  .ebook__size {
    color: #a6a6a6;
  }
  .ebook__author {
    color: #5c5c5c;
  }

  .ebook__title {
    color: #323232;
  }
}

.dark {
  .ebook__size {
    color: #a6a6a6;
  }
  .ebook__author {
    color: #9c9c9c;
  }

  .ebook__title {
    color: #ffffff;
  }
}

.ebook__controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding-top: 6px;
  margin-left: auto;
  height: 80px;

  .document__delete-icon {
    margin: 12px 0;
    width: 18px;
  }
}

.ebook__thumbnail-container {
  padding: 0;
  height: 80px;
}

.ebook__download-button {
  border: 1px solid #ffffff;
}
