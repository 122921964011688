@import "app/frontend/stylesheets/_user-variables.scss";

@import 'swiper/swiper-bundle';
@import 'swiper/swiper';

.carousel-block {
  margin: 5rem 0 5rem;
  font-family: 'DM Sans';
  font-style: normal;
}

.carousel-header-title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  color: #323232;
  margin-bottom: 3.5rem;
}

.carousel-footer-title {
  margin-top: 2.188rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  color: #323232;

  @media (min-width: 1200px) {
    right: -7%;
  }
}

.carousel {
  background-image: url('../assets/images/TV.png');
  background-repeat: no-repeat;
  background-size: 100% 118%;
  filter: drop-shadow(0px 1.147rem 2.762rem rgba(0, 0, 0, 0.237222))
    drop-shadow(0px 0.613rem 2.011rem rgba(0, 0, 0, 0.196715))
    drop-shadow(0px 0.344rem 1.127rem rgba(0, 0, 0, 0.165))
    drop-shadow(0px 0.183rem 0.599rem rgba(0, 0, 0, 0.133285))
    drop-shadow(0px 0.076rem 0.249rem rgba(0, 0, 0, 0.092778));
}

.carousel-nav-bar {
  display: flex;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 10;
}

.carousel-nav-item {
  width: 3rem;
  height: 3.063rem;
  background: $white;
  box-shadow: 0 0.125rem 0.625rem rgba(73, 73, 73, 0.1);
  border-radius: 0.5rem;
  //transition: 0.5s all;
  margin-right: 1.5rem;
  cursor: pointer;
}

.active-1 {
  width: 39.063rem;
  display: flex;
}

.active-2 {
  width: 18.438rem;
  display: flex;
}

.active-3 {
  width: 14.375rem;
  display: flex;
}

.active-4 {
  width: 18.438rem;
  display: flex;
}

.carousel-nav-title {
  font-weight: 400;
  font-size: 1rem;
  padding: 1.125rem 0;
  line-height: 1.25rem;
  color: #323232;
  display: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.carousel-nav-number-1 {
  width: 1rem;
  height: 2.125rem;
  background-image: url('../assets/images/Numbernum-1.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin: 0.438rem 1rem;
}

.carousel-nav-number-2 {
  width: 1rem;
  height: 2.125rem;
  background-image: url('../assets/images/Numbernum-2.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin: 0.438rem 1rem;
}

.carousel-nav-number-3 {
  width: 1rem;
  height: 2.125rem;
  background-image: url('../assets/images/Numbernum-3.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin: 0.438rem 1rem;
}

.carousel-nav-number-4 {
  width: 1rem;
  height: 2.125rem;
  background-image: url('../assets/images/Numbernum-4.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin: 0.438rem 1rem;
}

.slide {
  height: 34.605rem;
  position: relative;
}

.slide-img {
  width: 100%;
  height: 31.688rem;
  background-size: 105% 108%;
  padding: 1.188rem 1rem 0 1rem;
}

.arrow-buttons {
  position: relative;
}

.arrow-btn-left {
  position: absolute;
  left: -20%;
  top: 14.375rem;
  width: 4rem;
  height: 4rem;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0.25rem 2.5rem rgba(90 89 89 / 15%);
  text-align: center;
  padding-top: 1rem;
  z-index: 50;
  cursor: pointer;

  @media (max-width: 1200px) {
    left: -15%;
  }

  @media (max-width: 1400px) {
    left: -10%;
  }
}

.arrow-btn-right {
  position: absolute;
  right: -20%;
  top: 14.375rem;
  width: 4rem;
  height: 4rem;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0.25rem 2.5rem rgba(90 89 89 / 15%);
  text-align: center;
  padding-top: 1rem;
  z-index: 50;
  cursor: pointer;

  @media (max-width: 1200px) {
    right: -15%;
  }

  @media (max-width: 1400px) {
    right: -10%;
  }
}
