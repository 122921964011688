@import "app/frontend/stylesheets/_user-variables.scss";

.info-block {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  .text-light {
    color: #ffffff;
  }

  .title {
    font-family: DM Sans, serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #323232;
  }

  .link {
    font-family: DM Sans, serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #a6a6a6;
    cursor: pointer;
  }
}

.empty-list {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #8f8a9b;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: auto;
  width: 100%;
  height: 140px;
  background: #2b2834;
}
