@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';

.bookmark-wrapper .bookmark {
  margin: 0 16px;
  padding: 12px 0;
  border-bottom: 1px #444050 solid;
}

.create-mode .bookmark {
  margin-left: 0;
  margin-right: 0;
  padding: 12px 16px;
}

.bookmark-wrapper.dark:hover {
  background-color: #32303c;
}

.bookmark {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}

.bookmark-main {
  display: flex;
  flex: 0 0 100%;
}

.bookmark-timestamp {
  width: 100px;
  height: 26px;
  padding: 2px 12px;
  color: #d1d3d4;
  border-radius: 25px;
  border: 1px solid #444050;
  font-weight: 500;
  text-align: center;
}

.bookmark-title {
  cursor: pointer;
}

.bookmark-title-wrapper {
  position: relative;
  margin-left: 10px;
  color: $white;
  line-height: 26px;
  width: 100%;
}

.dark .new-bookmark-area {
  padding-top: 0;
}

.light .new-bookmark-area {
  padding-top: 4px;
}

.new-bookmark-area {
  cursor: default;
  padding-left: 0;

  &:focus {
    outline: none;
  }
}

.bookmark-title {
  cursor: pointer;
}

.new-bookmark-area,
.bookmark-title {
  background: transparent;
  border: none;
  color: $white;
  resize: none;
  display: flex;
  width: 85%;
  flex-basis: 100%;
  overflow-wrap: anywhere;

  &:focus {
    outline-width: 0;
    cursor: auto;
  }
}

.bookmark-counter {
  justify-content: flex-end;
  display: flex;
}

.bookmark-actions {
  height: 30px;
}

.bookmark-action {
  margin: 0 5px;
}

.delete-action {
  margin-right: 16px;
}

.tags-button {
  padding: 3px 12px;
  font-size: 14px;
  border-radius: 25px;
  background-color: #3b3946;
  border: 1px solid #444050;
  color: $white;
}

.bookmark-edit-actions {
  position: relative;
}

.bookmark-edit-actions,
.bookmark-edit-actions form,
.bookmark-actions {
  display: flex;
  margin-left: auto;
  justify-content: end;
  width: 205px;
}

.bookmark__action-button {
  border-radius: 4px;
  border: none;
  font-size: 14px;
  height: 32px;
  width: 85px;
  color: $white;
}

.bookmark__save-button {
  @extend .bookmark__action-button;
  background-color: #6998ae;
  margin: 0 8px;

  &:disabled,
  &[disabled] {
    color: #8f8a9b;
    background-color: #3b3946;
  }
}

.bookmark__cancel-button {
  @extend .bookmark__action-button;
  background-color: #444050;
  margin-left: auto;
}

.light .bookmark {
  margin: 0;
  padding: 12px 0;
  position: relative;
  border-bottom: 1px #eaedee solid;

  &:hover {
    background-color: #f9f9f9;

    .bookmark-edit-tags__tag {
      background-color: $white;
    }
  }

  .bookmark-timestamp {
    color: #5c5c5c;
    border: 1px solid #d1d3d4;
  }

  .bookmark-title-wrapper {
    color: #323232;

    .new-bookmark-area,
    .bookmark-title {
      color: #323232;
      line-height: 18px;
    }
  }

  .tags-button {
    background-color: $white;
    border: 1px solid #d1d3d4;
    color: #5c5c5c;
    height: 26px;
    width: 54px;
    border-radius: 12px;
    padding: 2px 12px;
    vertical-align: center;
    font-size: 14px;
    line-height: 18px;
  }

  .tags-area {
    position: relative;
  }

  .bookmark-actions {
    color: #5c5c5c;
    align-items: center;
  }

  .bookmark__action-button {
    color: $white;
  }

  .bookmark__save-button {
    @extend .bookmark__action-button;
    background-color: #6998ae;
    margin: 0 8px;

    &:disabled,
    &[disabled] {
      color: $quick-silver;
      background: #f9f9f9;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .bookmark__cancel-button {
    box-shadow: 0 2px 10px rgba(73, 73, 73, 0.1);
    border-radius: 4px;
    background-color: $white;
    color: #6998ae;
  }
}

.bookmark-edit-tags {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  margin-top: 10px;
}

.bookmark-edit-tags__title {
  font-size: 14px;
  flex-grow: 0.02;
  margin: auto 0;
}

.bookmark-edit-tags__tag {
  display: flex;
  padding: 4px 10px;
  margin: 3px 3px;
  border-radius: 20px;
  font-size: 12px;
  justify-content: space-between;
  flex-grow: 0.02;
}

.bookmark-edit-tags__tag-remove {
  margin: auto 0;
}

.tags-limit-message {
  font-size: 14px;
  line-height: 18px;
  margin: auto 3px;
}

// theme
$edit-tags-color-light: #5c5c5c;
$tag-border-light: #d1d3d4;
$tags-limit-color-light: #8f8a9b;
$edit-tags-color-dark: #d1d3d4;
$tag-border-dark: #444050;
$tags-limit-color-dark: #8f8a9b;

.light {
  .bookmark-edit-tags__title {
    color: $edit-tags-color-light;
  }

  .bookmark-edit-tags__tag-name {
    color: $edit-tags-color-light;
  }

  .bookmark-edit-tags__tag {
    border: 1px solid $tag-border-light;
  }

  .tags-limit-message {
    color: $edit-tags-color-light;
  }

  .bookmark-title {
    margin-top: 4px;
  }
}

.dark {
  .bookmark-edit-tags__title {
    color: $edit-tags-color-dark;
  }

  .bookmark-edit-tags__tag-name {
    color: $edit-tags-color-dark;
  }

  .bookmark-edit-tags__tag {
    border: 1px solid $tag-border-dark;
  }

  .tags-limit-message {
    color: $tags-limit-color-dark;
  }

  .new-bookmark-area {
    height: 32px;
  }
}

.bookmark-edit-tags {
  padding-left: 5px;
}
