@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../shared/assets/styles/video_resources';
@import '../../../shared/assets/styles/document';

.transcript {
  &.document__container {
    width: 100%;
    border: none;
    gap: 10px;
    height: 39px;
    padding: 0;

    &:hover {
      background: #ffffff;
      cursor: default;
    }
  }

  .document__text {
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

.transcript__button {
  border-radius: 4px;
  background: #323232;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  width: 142px;
  height: 62px;
  padding: 12px 0px;

  &.disabled {
    background: #a6a6a6;

    &:hover {
      cursor: default;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 2px 3px rgba(23, 24, 28, 0.6);
  }
}

.transcript__button-text {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.transcript__form-control {
  display: flex;
  column-gap: 16px;
  flex-wrap: wrap;
  flex-direction: row;
}

.transcript__error {
  width: 100%;
  position: relative;
  right: 110px;
  top: 12px;
  color: #d35163;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.transcripts__attach {
  width: 171px;
  padding: 6px 12px;
}
