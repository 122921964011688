@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../shared/assets/styles/video_resources';
.simple-button {
  .drag-drop-area {
    border-radius: 4px;
    background: #323232;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    width: 170px;
    height: 62px;
    padding: 12px 0px;

    &.disabled {
      background: #a6a6a6;
      cursor: default;

      &:hover {
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
      }
    }

    &:hover {
      box-shadow: 0 2px 3px rgba(23, 24, 28, 0.6);
    }
  }

  .file-info__text {
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}

.portrait {
  .drag-drop-area {
    background-image: url("data:image/svg+xml,%3Csvg width='133' height='133' viewBox='0 0 137 137' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='133' height='133' rx='6' stroke='%23A1A1A1' stroke-linecap='round' stroke-dasharray='5 7'/%3E%3C/svg%3E%0A");
    cursor: pointer;
    width: 133px;
    height: 133px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file-info__text {
    max-width: 133px;
    max-height: 133px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #a1a1a1;
  }

  .file-info {
    flex-direction: column;
    text-align: center;
    width: 133px;
  }
}

.drag-drop-container {
  display: flex;
  flex-direction: column;
}

.drag-drop-area {
  background-image: url("data:image/svg+xml,%3Csvg width='285' height='69' viewBox='0 0 285 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='283' height='67' rx='6' stroke='%23A1A1A1' stroke-linecap='round' stroke-dasharray='5 7'/%3E%3C/svg%3E%0A");
  cursor: pointer;
  width: 285px;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 295px;
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;

  &.simple-button {
    color: #a6a6a6;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    width: 170px;
  }
}

.file-info__text {
  max-width: 235px;
  max-height: 69px;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #a1a1a1;

  &.active {
    color: #323232;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
}

.upload-icon {
  width: 22px;
  height: 17px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='17' viewBox='0 0 22 17' fill='none'%3E%3Cpath d='M18.7928 10.625V13.4583C18.7928 13.8341 18.6042 14.1944 18.2686 14.4601C17.933 14.7257 17.4779 14.875 17.0033 14.875H4.47697C4.00238 14.875 3.54722 14.7257 3.21162 14.4601C2.87603 14.1944 2.6875 13.8341 2.6875 13.4583V10.625' stroke='%23A1A1A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.26562 7.08594L10.7393 10.6276L15.213 7.08594' stroke='%23A1A1A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.7344 10.625V2.125' stroke='%23A1A1A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.file__thumbnail {
  object-fit: cover;
  width: 130px;
  height: 130px;
  border-radius: 4px;
  cursor: pointer;
}

.file-info__hint {
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #a6a6a6;
  text-align: center;
}

progress::-moz-progress-bar {
  border-radius: 3px;
  background: #323232;
}
progress::-webkit-progress-value {
  border-radius: 3px;
  background: #323232;
}
progress {
  border-radius: 3px;
  color: #323232;
  width: 95%;
  height: 5px;
  margin-top: 3px;
}
