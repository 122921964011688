@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../shared/assets/styles/video_resources';
@import '../../../shared/assets/styles/document';

.document__container.ebook__container {
  height: 80px;
  padding: 0 16px 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border: 0;
  justify-content: flex-start;
  width: 320px;

  &.light:hover {
    background-color: #ffffff;
  }
}

.video-resources_download {
  &:hover {
    text-decoration: none;
  }
}

.ebook__item-thumbnail {
  width: 64px;
  height: 80px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 4px;
}

.ebook__info {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  height: 80px;
}

.ebook__size {
  padding-top: 26px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #a6a6a6;
}

.ebook__author {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #5c5c5c;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 140px;
}

.ebook__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #323232;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 140px;
}

.light {
  .ebook__size {
    color: #a6a6a6;
  }
  .ebook__author {
    color: #5c5c5c;
  }

  .ebook__title {
    color: #323232;
  }
}

.dark {
  .ebook__size {
    color: #a6a6a6;
  }
  .ebook__author {
    color: #9c9c9c;
  }

  .ebook__title {
    color: #ffffff;
  }
}

.ebook__controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  height: 80px;

  .document__delete-icon {
    margin: 12px 0 12px 3px;
    width: 18px;
  }
}

.ebook__thumbnail-container {
  padding: 0;
  height: 80px;
}

.ebook__download-button {
  border: 1px solid #ffffff;
}

@media (max-width: 991px) {
  .document__preview-title {
    display: none;
  }
  .document__container.ebook__container {
    display: none;
  }
  .document__description {
    width: 100%;
  }
}
