@import "app/frontend/stylesheets/_user-variables.scss";

.special-episode-labels-container {
  display: flex;
  margin: 6px 0;
}
.special-episode-label {
  font-size: 12px;
  font-weight: 400;
  padding: 4px 5px;
  margin-right: 2px;
  border-radius: 6px;
  color: #fff;

  &.premiering {
    background: #d83f76;
  }
  &.exclusive {
    background: #309967;
  }
  &.live_in {
    background: #5a3cd2;
  }
  .special-episode-tooltip-wrapper {
    position: relative;
    .special-episode-tooltip {
      display: none;
      position: absolute;
      bottom: 60px;
      left: 50%;
      transform: translateX(-50%);
      background: #2b2834;
      width: 276px;
      max-width: 48vw;
      padding: 16px 12px 20px 16px;
      border-radius: 8px;
      border: 1px;
      border: 1px solid #6998ae;
      z-index: 5;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -28px;
        width: 0;
        height: 0;
        border: 14px solid transparent;
        border-top-color: #6998ae;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -27px;
        width: 0;
        height: 0;
        border: 14px solid transparent;
        border-top-color: #2b2834;
      }
    }
  }
}
.special-episode-label:hover {
  .special-episode-tooltip {
    display: block;
  }
}

.search-tooltip {
  margin-bottom: -25px;
  margin-left: 35%;
}
