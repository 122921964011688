@import "app/frontend/stylesheets/_user-variables.scss";

.tags-list-block {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
  min-height: 24px;
  gap: 6px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-right: 48px;
}

.selected-tags {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
}

.selected-tag {
  display: flex;
  flex-wrap: wrap;
  height: 24px;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid var(--text-disabled, #d1d3d4);
  background: var(--backgrounds-white, #fff);
  color: var(--text-secondary, #5c5c5c);
  text-align: center;
  font-size: 12px;
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.clear-tags-button {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 70px;
  height: 24px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--primary-steel-blue-default, #6998ae);
  background: var(--primary-steel-blue-default, #6998ae);
  color: var(--backgrounds-white, #fff);
  text-align: center;

  font-size: 12px;
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
}
