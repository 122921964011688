@import "app/frontend/stylesheets/_user-variables.scss";

.entries-carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 20px 0;
}

.entries-carousel {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.3s ease-in-out;
  gap: 29px;
}

.entries-carousel-item {
  flex: 0 0 auto;
  width: calc((100% - 60px) / 3);
  max-width: 270px;
  min-width: 200px;
}

.entries-carousel-button {
  position: absolute;
  display: flex;
  cursor: pointer;
  width: 64px;
  height: 140px;
  top: 0;
  padding: 50px 22px;
  font-size: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #ffffff;
  background: rgba(11, 9, 15, 0.8);
  box-shadow: 0 4px 40px 0 rgba(167, 167, 167, 0.15);
  z-index: 10;
}

.entries-carousel-button__left {
  left: 0;
}

.entries-carousel-button__right {
  right: 0;
}

.entries-carousel-button__light {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  color: #131118;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
  }
}

.all-card {
  flex: 0 0 24%;
  min-width: 200px;
  max-width: 260px;
  height: 140px;
  background-color: #32303c;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%;
}

.all-card__light {
  background-color: #8f8a9b;
}
