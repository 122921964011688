@import "app/frontend/stylesheets/_user-variables.scss";

.transcript__preview-block {
  position: relative;
  width: 400px;
  height: 225px;

  .transcript-preview.video-js {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  .transcript-preview.video-js button.vjs-big-play-button {
    margin-left: 36%;
    margin-top: 18%;
  }

  .transcript-preview.video-js .vjs-time-control {
    display: block;
    padding-left: 3px;
    padding-right: 3px;
    min-width: 3px;
  }

  .transcript-preview.video-js .vjs-remaining-time {
    display: none;
  }
}

