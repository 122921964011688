.documents__container {
  width: 95%;
  min-height: 610px;
}

.documents__title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 16px;
}

.documents__secondary-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 10px;
}

.documents__create,
.documents__index {
  padding: 16px;
}

.documents__list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

// THEME

.dark {
  .documents__container {
    background-color: #2b2834;
    min-height: unset;
    padding: 0;
    width: 100%;
  }
  .documents__index {
    padding: 0;
  }
  .documents__title {
    color: #fff;
  }
  .documents__secondary-title {
    color: #fff;
  }
  .documents__create {
    border-bottom: 1px solid #eaedee;
  }
}

.light {
  .documents__container {
    background-color: #ffffff;
  }
  .documents__title {
    color: #323232;
  }
  .documents__secondary-title {
    color: #5c5c5c;
  }
  .documents__create {
    border-bottom: 1px solid #eaedee;
  }
}

.documents-loader {
  margin: 75px auto;
  width: 135px;
  display: flex;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .dark {
    .documents__container {
      background-color: #1c1923;
    }
  }
}