@import "app/frontend/stylesheets/_user-variables.scss";

.read-more-link {
  display: flex;
  font-family: DM Sans, serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #8f8a9b;
  cursor: pointer;

  &:hover {
    color: #8f8a9b;
  }
}

.comment-wrapper .comment {
  padding: 12px 16px;
  border-bottom: 1px #444050 solid;
}

.create-mode .comment {
  margin-left: 0;
  margin-right: 0;
  padding: 12px 16px;
}

.comment-wrapper.dark:hover {
  background-color: #32303c;
}

.comment {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}

.comment-main {
  display: flex;
  flex: 0 0 100%;
}

.comment-block {
  display: flex;
  flex-direction: column;
}

.comment-title {
  display: flex;
  flex-direction: row;
  gap: 8px;

  .timestamp {
    color: #8f8a9b;
    font-weight: 400;
  }

  .edit-info {
    color: #8f8a9b;
    font-weight: 400;
  }
}

.comment-title-wrapper {
  position: relative;
  margin-left: 10px;
  color: $white;
  line-height: 26px;
  width: 100%;
}

.dark .new-comment-area {
  padding-top: 0;
  cursor: pointer;
}

.light .new-comment-area {
  padding-top: 4px;
}

.new-comment-area {
  cursor: default;
  padding-left: 0;

  &:focus {
    outline: none;
  }
}

.comment-counter__edit {
  color: #8f8a9b;
}

.comment-title {
  cursor: pointer;
}

.new-comment-area,
.comment-title {
  background: transparent;
  border: none;
  color: $white;
  resize: none;
  display: flex;
  width: 100%;
  flex-basis: 100%;
  overflow-wrap: anywhere;
  min-height: 32px;

  &:focus {
    outline-width: 0;
    cursor: auto;
  }
}

.comment-counter {
  justify-content: flex-end;
  display: flex;
}

.comment-actions {
  height: 30px;
}

.comment-action {
  margin: 0 5px;
}

.delete-action {
  margin-right: 16px;
}

.comment-edit-actions {
  position: relative;
}

.comment-edit-actions,
.comment-edit-actions form,
.comment-actions {
  display: flex;
  margin-left: auto;
  justify-content: end;
  width: 205px;
}

.comment__action-button {
  border-radius: 4px;
  border: none;
  font-size: 14px;
  height: 32px;
  width: 85px;
  color: $white;
}

.comment__save-button {
  @extend .comment__action-button;
  background-color: #6998ae;
  margin: 0 8px;

  &:disabled,
  &[disabled] {
    color: #8f8a9b;
    background-color: #3b3946;
  }
}

.comment__cancel-button {
  @extend .comment__action-button;
  background-color: #444050;
  margin-left: auto;
}

.comment-user-block {
  display: flex;
  justify-content: center;

  .avatar {
    width: 24px;
    height: 24px;
    background-color: #8f8a9b;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .initial {
    color: #fff;
    text-align: center;
    font-family: 'DM Sans', serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.021px;
  }
}

.actions-block {
  display: flex;
  margin-left: auto;
  position: relative;

  .actions-btn {
    display: flex;
  }

  .actions-modal {
    position: absolute;
    top: 25px;
    right: 10px;
    min-width: 140px;
    z-index: 1;
    border-radius: 8px;
    padding: 16px 8px 16px 8px;
    height: auto;
    background-color: #2b2834;
    -webkit-box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.7);
    box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .action-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .text {
      font-family: DM Sans, serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color: #ffffff;
    }
  }
}

.verified-icon {
  margin-top: 3px;
}
