@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';

.thumbnail-container {
  width: 180px;
  height: 90px;
  position: relative;

  .thumbnail {
    object-fit: cover;
  }

  .progress-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .duration {
    position: absolute;
    width: 69px;
    height: 24px;
    bottom: 0;
    right: 0;
    background-color: #1c1923;
    color: #ffffff;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
    font-weight: 500;
    opacity: 0.8;
  }
  .duration-with-offset {
    bottom: 4px;
  }

  img {
    width: 180px;
    height: 90px;
  }
}

.episode-container {
  width: 100%;
  min-height: 220px;
  background-color: #ffffff;
  margin-bottom: 16px;
  padding: 16px;
}

.text-content {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
}

.title,
.series-title {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 180px;
  white-space: nowrap;
}

.title {
  height: 18px;
  margin-top: 6px;
  font-size: 14px;
  color: #323232;
}

.series-title {
  margin-top: 2px;
  height: 18px;
  font-size: 14px;
  line-height: 18px;
  color: #5c5c5c;
}

.toggle-container {
  margin-top: 12px;
}

.show-more,
.show-less {
  cursor: pointer;
  background-color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #a6a6a6;
}

.episode-notes-container {
  padding-left: 24px;
  width: calc(100% - 180px);
}

.speakers-short {
  display: flex;

  .speaker-avatar {
    width: 16px !important;
    height: 16px !important;
    border: 0.5px solid #d1d3d4;
    border-radius: 50%;

    &:not(:first-child) {
      margin-left: -12px;
    }
  }

  .speaker-name {
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5c5c5c;
    overflow: hidden;
    margin-left: 6px;
    margin-top: 8px;
  }
}
