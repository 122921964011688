@import "app/frontend/stylesheets/_user-variables.scss";

.copyright-radio-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.radio-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;

  .radio-label {
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-right: 24px;
    color: var(--text-primary, #323232);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: DM Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.radio-custom-block {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-top: -16px;
}

.custom-copyright-input {
  width: 236px;
  border-radius: 2px;
  border: 1px solid #d1d3d4;
  background: #fff;
  color: var(--text-primary, #323232);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: DM Sans, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  &::placeholder {
    color: #a6a6a6;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: DM Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

input[type='radio']:after {
  width: 24px;
  height: 24px;
  border-radius: 15px;
  top: -7px;
  right: 5px;
  position: relative;
  background-color: #ffffff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 3px solid #ffffff;
  outline: 1px solid #d1d3d4;
}

input[type='radio']:checked:after {
  width: 24px;
  height: 24px;
  border-radius: 15px;
  top: -7px;
  right: 5px;
  position: relative;
  background-color: #414042;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 3px solid #ffffff;
  outline: 1px solid #414042;
}

.error-message {
  display: flex;
  justify-content: start;
  color: var(--system-status-error-icon-fill, #d35163);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: DM Sans, serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.custom-label {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.custom-input-messages {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.counter {
  display: flex;
  margin-left: auto;
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #a6a6a6;
  font-family: 'DM Sans', serif;
  font-style: normal;
}
