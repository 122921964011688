@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';
.social-hint__wrapper {
  position: fixed;
  .social-hint {
    position: fixed;
    display: none;
    height: 32px;
    width: 104px;
    background-color: #6998ae;
    color: #ffffff;
    border-radius: 4px;

    &:after,
    &:before {
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      left: 50%;
      top: 100%;
      border: solid transparent;
    }
    &:after {
      margin-left: -4px;
      border-width: 4px;
      border-color: #6998ae transparent transparent transparent;
    }
    &:before {
      margin-left: -8px;
      border-width: 8px;
      border-color: #6998ae transparent transparent transparent;
    }

    a {
      margin: 2px 5px;
      cursor: pointer;
      color: white;
      &:hover {
        color: white;
      }
      .feather-x-twitter path {
        fill: white;
      }
      .feather-linkedin {
        fill: white;
        stroke: none;
      }
    }

    .copy_current_text svg {
      stroke: #ffffff;
    }
  }

  .copy_current_url-tooltip__container {
    position: fixed;
    right: 20%;

    span {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      padding: 6px 12px;
      background: #ffffff;
      box-shadow: 0 2px 15px rgba(73, 73, 73, 0.2);
      border-radius: 4px;
      color: #323232 !important;
    }
  }
}
