@import "app/frontend/stylesheets/_user-variables.scss";

.article-card-block {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 260px;
  flex: 1;

  .thumbnail {
    height: 140px;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;', serif;
    margin-bottom: 8px;
  }
}

.article-card__content {
  display: flex;
  flex-direction: column;
}

.card-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'DM Sans', sans-serif !important;
  cursor: pointer;
}

.card-title__light {
  color: #323232;
}

.card-description {
  margin-top: 4px;
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  font-family: 'DM Sans', sans-serif !important;
  white-space: break-spaces;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.card-description__light {
  color: #5c5c5c;
}

.topics-block {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 6px;
}

.topic {
  padding: 4px 10px 4px;
  font-size: 12px;
  line-height: 16px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 400;
  background-color: #3b3946;
  color: #d1d3d4;
  border: 1px solid #444050;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.topic__light {
  color: #5c5c5c;
  background-color: #fff;
  border: 1px solid #d1d3d4;
}

.card-link,
.topic-link {
  color: inherit;
  white-space: break-spaces;
  cursor: pointer;
  line-height: 0;
}

.footnote {
  font-size: 0.875rem;
}

.card-subject {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 12px;
  color: #bb711a;
}

.article-new-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.25rem 0.75rem;
  position: absolute;
  width: 49px;
  height: 1.5rem;
  left: 0;
  top: 19px;
  background: #d18f42;
  border-radius: 0 4px 4px 0;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}
