@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';

.message-seen {
  height: 8px;
  width: 8px;
  background-color: #d18f42;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  top: 16px;
}
.message-bubble {
  position: relative;
  background-color: $white;
  padding: 16px 24px 16px 32px;
  border-radius: 8px;
  margin-bottom: 16px;
}
.message-title {
  font-size: 16px;
  font-weight: 500;
  color: #323232;
  overflow-wrap: break-word;
}
.message-text {
  font-size: 14px;
  color: #5c5c5c;
  margin-bottom: 6px;
  overflow-wrap: break-word;
}

.message-sent-at {
  margin-top: 20px;
  color: #5c5c5c;
  font-size: 12px;
  text-align: right;
}

.message-date-label {
  font-size: 14px;
  color: #5c5c5c;
  text-align: center;
  font-weight: 500;
  margin: 8px 0;
}
.image-pop-up {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(28, 25, 35, 0.6);
  z-index: 999999;

  img {
    box-shadow: 0 4px 30px rgba(23, 24, 28, 0.6);
    border-radius: 4px;
    transform: translate(-50%, -50%);
    height: 500px;
    margin-top: 50vh;
    margin-left: 50vw;
  }
}
.message-image-in-text {
  max-width: 100%;
}
