@import "app/frontend/stylesheets/_user-variables.scss";

.transcript-preview__title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 20px;
  display: flex;
}

.transcript-preview {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 450px;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 7px;
    right: -30px;

    &:hover {
      width: 13px;
    }
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #8f8a9b;
    border-radius: 4px;
    border: 4px solid #8f8a9b;
    height: 40px;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}
.transcript-preview__resume {
  display: inline-flex;
  padding: 6px 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  background: #6998ae;
  box-shadow: 0px 24px 44px 0px rgba(29, 68, 86, 0.16);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.021px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 10px;
}

.transcript-preview__resume-icon {
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cg clip-path='url(%23clip0_23077_47802)'%3E%3Cpath d='M8.66583 12.667V5.22031L11.9192 8.47365C12.1792 8.73365 12.6058 8.73365 12.8658 8.47365C13.1258 8.21365 13.1258 7.79365 12.8658 7.53365L8.4725 3.14031C8.2125 2.88031 7.7925 2.88031 7.5325 3.14031L3.1325 7.52698C2.8725 7.78698 2.8725 8.20698 3.1325 8.46698C3.3925 8.72698 3.8125 8.72698 4.0725 8.46698L7.3325 5.22031V12.667C7.3325 13.0336 7.6325 13.3336 7.99917 13.3336C8.36583 13.3336 8.66583 13.0336 8.66583 12.667Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_23077_47802'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.dark {
  .transcript-preview {
    height: 775px;
    overflow: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 7px;

      &:hover {
        width: 10px;
      }
    }
  }
}

.light {
  .transcript-preview__resume {
    color: #ffffff;
  }
}
