@import "app/frontend/stylesheets/_user-variables.scss";

.info-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  margin-top: 52px;

  .decoration {
    width: 32px;
    height: 4px;
    background-color: #d18f42;
  }

  .title {
    font-family: DM Sans, serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: #323232;
  }
}

.entries-block {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media (max-width: 463.98px) {
    justify-content: center;
  }
}

.loader {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-light {
  color: #ffffff;
}
