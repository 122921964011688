@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';
.bookmark-tags-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
}
.bookmark-tags-container {
  position: absolute;
  padding: 12px;
  border-radius: 4px;
  min-width: 230px;
  max-width: 350px;
  width: auto;
  font-size: 12px;
  z-index: 2;

  .bookmark-tags {
    margin: 0 -3px;
    flex-wrap: wrap;
    display: flex;
  }

  .bookmark-tag {
    border-radius: 12px;
    padding: 4px 10px;
    margin: 6px 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bookmark-tags-title {
    font-size: 12px;
  }
  .bookmarks-tags-heading {
    display: flex;
    justify-content: space-between;
  }
}
.bookmark-tags-container.dark {
  background-color: #2b2834;
  border: 1px solid #3b3946;
  margin-top: 2px;

  .bookmark-tags-title {
    color: $white;
  }
  .bookmark-tag {
    border: 1px solid #444050;
    background-color: #3b3946;
    color: #d1d3d4;
  }
}
.bookmark-tags-container.light {
  background-color: $white;
  border: 1px solid #d1d3d4;
  top: 30px;
  right: 0;
  .bookmark-tags-title {
    color: #323232;
  }
  .bookmark-tag {
    border: 1px solid #d1d3d4;
    background-color: $white;
    color: #5c5c5c;
  }
}
