.video-resources__form-control {
  display: flex;
  width: 517px;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 6px;
}

.video-resources__form-error {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #d35163;
}

.video-resources__file__icon {
  position: relative;
}
.video-resources__file__format {
  position: absolute;
  bottom: -4px;
  right: -9px;
  background: #d35163;
  color: white;
  font-size: 10px;
  line-height: 12px;
  padding: 0 2px;
}
.video-resources__file__icon:before {
  content: '';
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_18732_50773)'%3E%3Cpath d='M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z' fill='%235C5C5C'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_18732_50773'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  display: block;
}

.dark .video-resources__file__icon:before {
  content: '';
  background: #fff;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_18732_50773)'%3E%3Cpath d='M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z' fill='%235C5C5C'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_18732_50773'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_18732_50773)'%3E%3Cpath d='M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z' fill='%235C5C5C'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_18732_50773'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  display: block;
}

.video-resources__control-buttons {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

.video-resources__control-button,
.video-resources__control-secondary-button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 3px 50px;
}
.dark .video-resources__control-button {
  color: #5c5c5c;

  &:disabled {
    background-color: #f1f3f4;
  }

  &:hover {
    background-color: #fafffa;
  }
}
.light .video-resources__control-button {
    background-color: #000000;
    color: #FFFFFF;

    &:disabled {
      background-color: #555555;
    }

    &:hover {
      box-shadow: 0 2px 3px rgba(23, 24, 28, 0.6);
    }
}

.video-resources__control-secondary-button {
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #323232;

  &:disabled {
    background-color: #f1f3f4;
  }

  &:hover {
    box-shadow: 0 2px 3px rgba(200, 200, 200, 0.6);
  }
}

.video-resources__container .video-resources__cursor-default {
  cursor: unset;
}

.video-resources__controls {
  margin-left: auto;
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
}