@import "app/frontend/stylesheets/_user-variables.scss";

.links__container {
  background-color: #ffffff;
  width: 95%;
  min-height: 610px;
}

.links__title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #323232;
  margin-bottom: 16px;
}

.links__secondary-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5c5c5c;
  padding-bottom: 10px;
}

.links__create,
.links__index {
  padding: 16px;
}

.links__create {
  border-bottom: 1px solid #eaedee;
}
