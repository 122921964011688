@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';
$edit-tags-color-light: #5c5c5c;
$tag-border-light: #d1d3d4;
$tags-limit-color-light: #8f8a9b;

.button {
  border-radius: 4px;
  border: none;
  font-size: 14px;
  height: 32px;
  width: 80px;
  color: $white;
  justify-content: center;
  display: flex;
  padding: 6px 0;
}

.save {
  @extend .button;
  background-color: #6998ae;
  margin: 0 8px;
  cursor: pointer;
  opacity: 1;

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.cancel {
  @extend .button;
  box-shadow: 0 2px 10px rgba(73, 73, 73, 0.2);
  background-color: $white;
  color: #6998ae;
  cursor: pointer;
}

.modal-header {
  color: #323232;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
  display: flex;
  padding: 16px 0;
}

svg {
  margin-right: 8px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
}

.modal-text {
  color: #323232;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding: 16px 0;
  border-top: 1px solid #eaedee;
  border-bottom: 1px solid #eaedee;
  overflow-y: scroll;
  max-height: 200px;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
    height: 6px;
    border: none;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: transparent;
    display: none;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #8f8a9b;
    border: 4px solid #8f8a9b;
    border-radius: 4px;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.modal-tags {
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
}

.bookmark-edit-tags__title {
  font-size: 14px;
  flex-grow: 0.02;
  margin: auto 0;
  color: $edit-tags-color-light;
}

.bookmark-edit-tags__tag {
  display: flex;
  padding: 4px 10px;
  margin: 3px 3px;
  border-radius: 20px;
  font-size: 12px;
  justify-content: space-between;
  flex-grow: 0.02;
  border: 1px solid $tag-border-light;
}

.bookmark-edit-tags__tag-remove {
  margin: auto 0;
}

.tags-limit-message {
  font-size: 14px;
  line-height: 18px;
  margin: auto 3px;
  color: $edit-tags-color-light;
}

.bookmark-edit-tags__tag-name {
  color: $edit-tags-color-light;
}
