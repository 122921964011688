@import "app/frontend/stylesheets/_user-variables.scss";

.featured-videos-block {
  max-width: 870px;
  margin-top: 45px;
  display: block;
  flex-direction: column;

  .decoration {
    width: 32px;
    height: 2px;
    border: #d18f42 solid 2px;
    margin-bottom: 12px;
  }

  .loader {
    display: flex;
    width: 32px;
    height: 32px;
    margin: 0 auto;
  }

  .title {
    width: 100%;
    display: flex;
    justify-items: start;
    align-items: center;
    font-family: DM Sans, serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    color: #323232;
  }
}
