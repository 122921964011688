@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';

.bookmarks-header {
  font-weight: 500;
  display: flex;
  justify-content: space-between;

  .title-text {
    font-size: 20px;
    color: $white;
    margin-right: 4px;
  }

  .counter {
    font-size: 14px;
    color: $quick-silver;
  }
}

.bookmarks-title {
  margin: auto 0;
}

.bookmarks-container {
  display: flex;
  background-color: #2b2834;
  border-radius: 8px;
  padding-inline-start: 0;
  padding-bottom: 34px;
  height: 300px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;

    &:hover {
      width: 10px;
    }
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: transparent;
    //&:hover {
    //  background-color: #d9d9d9;
    //  opacity: .1;
    //
    //}
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #8f8a9b;
    border-radius: 4px;
    border: 4px solid #8f8a9b;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.bookmarks-list {
  flex-wrap: wrap;
  width: 100%;
}

.bookmarks-loader {
  margin: 50px auto;
  width: 200px;
  display: flex;
  vertical-align: middle;
}

.bookmarks-form-container {
  min-height: 55px;
}
