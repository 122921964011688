@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';

.episodes-block {
  width: 41.75rem;
  height: 21.625rem;
  left: 32.125rem;
  top: 18.438rem;
  background: #ffffff;
  box-shadow: 0 0.25rem 2.5rem rgba(167, 167, 167, 0.15);
  border-radius: 0.75rem;
  padding: 6.563rem 4.875rem 6.563rem 4.875rem;
  text-align: center;
  margin-right: 3.2rem;
}

.main-block {
  width: 100%;
  padding: 0 16px 0 32px;
  margin-bottom: 72px;
  display: flex;
}

.bookmarks-title-row {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  margin-right: 48px;

  .bookmarks-title {
    height: 42px;
    vertical-align: middle;
    display: flex;
  }

  .title-text {
    font-size: 24px;
    color: #323232;
  }

  .counter {
    font-size: 14px;
    margin: auto 4px;
    color: #a6a6a6;
  }
}

.episodes-placeholder {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  height: 624px;
  .episodes-block {
    overflow: hidden;
  }
  .placeholder-border {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -6.563rem;
      left: -4.875rem;
      width: 41.75rem;
      height: 4px;
      background: #d18f42;
    }
  }
}

.placeholder-button {
  height: 56px;
  width: 300px;
}

.primary-placeholder-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  text-align: center;
  color: #5c5c5c;
}

.secondary-placeholder-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  text-align: center;
  color: #323232;
  margin-bottom: 20px;
  margin-top: 12px;
}

.bookmarks-search {
  font-size: 12px;
  color: #5c5c5c;
  background-color: #dbebf0;
  padding: 4px 12px;
  font-weight: 400;
}

.bookmarks-filter-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 32px;
  height: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-right: 48px;
}

.filter-bookmark__no-result {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;

  color: var(--text-secondary, #5c5c5c);
  font-size: 14px;
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 16px;
}
