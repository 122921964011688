@import "app/frontend/stylesheets/_user-variables.scss";

.news-bookmark__container {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 16px 0 16px 16px;
  border-bottom: 1px solid #d1d3d4;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &.archived {
    background-color: #f9f9f9;

    .bookmark-action {
      display: flex;
    }

    .news-bookmark__text {
      opacity: 0.5;
    }

    &:hover {
      .news-bookmark__text {
        opacity: 0.6;
        max-height: 900px;
        transition-duration: 300ms;
      }
    }
  }

  &.active {
    background-color: #f3f4f5;
    opacity: 0.9;

    .news-bookmark__text {
      max-height: 900px;
      transition-duration: 300ms;
    }

    .bookmark-action {
      display: flex;
    }

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    opacity: 1;
    background-color: #f3f4f5;

    .bookmark-action {
      display: flex;
    }

    .news-bookmark__text {
      max-height: 900px;
      transition-duration: 300ms;
    }
  }
}

.bookmark-action {
  margin: 0 5px;
  cursor: pointer;
}

.tags-button {
  padding: 3px 12px;
  font-size: 14px;
  border-radius: 25px;
  border: 1px solid #a6a6a6;
  color: #3b3946;
  background-color: transparent;
}

.news-bookmark__actions {
  display: flex;
  margin-left: auto;
  gap: 10px;
  min-width: 64px;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.news-bookmark__text {
  cursor: pointer;
  min-width: 258px;
  max-height: 72px;
  overflow: hidden;
  transition-duration: 300ms;
}

.tags-area {
  position: relative;
}

.profile {
  .news-bookmark__actions {
    margin-left: auto;
    gap: 6px;
    min-width: 120px;
    height: 100%;
    flex-direction: row;
    align-items: center;
  }

  .edit-action {
    order: 2;
  }

  .delete-action {
    order: 3;
  }

  .news-bookmark__tags-area {
    order: 1;
  }

  .tags-area {
    min-width: 50px;
  }

  .news-bookmark__text {
    width: 558px;
    max-height: 54px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .news-bookmark__container {
    padding-left: 0;
  }
}
