@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../shared/assets/styles/documents';
@import '../../../shared/assets/styles/video_resources';

.transcripts__loader-container {
  display: flex;
  flex-direction: column;
  max-width: 351px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.transcripts__loader-text {
  color: #a1a1a1;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.transcripts__container {
  .documents-loader {
    margin: auto;
    width: 24px;
    display: flex;
    vertical-align: middle;
  }

  .documents__container {
    min-height: 215px;
  }

  .documents__secondary-title {
    color: #5c5c5c;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .documents__create {
    border: 0;
    padding-bottom: 4px;
  }

  .documents__index {
    padding-top: 4px;
  }
}

.transcripts__description {
  max-width: 441px;
  height: 19px;
  color: #5c5c5c;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 28px;
}

.transcripts__attach {
  width: 171px;
  padding: 6px 12px;
}
