@import "app/frontend/stylesheets/_user-variables.scss";

@import '../shared/assets/styles/global';

.main-block-wrapper {
  position: relative;
  width: 100%;
  background-color: #1c1923;
  font-family: 'DM Sans', sans-serif;
  margin: 0 0 30px 0;
}

.main-block {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.688rem;
}

.recent ul,
.recent ol {
  margin-bottom: 0;
}

ul.navigation {
  width: 100%;
  padding-top: 18px;
  padding-left: 0;
  list-style-type: none;
  margin: 0 16px;
  border-bottom: 2px #32303c solid;

  li {
    display: inline-flex;
    margin-bottom: -2px;
    position: relative;

    a {
      font-size: 16px;
      color: #8f8a9b;
      padding: 12px 16px;
      font-weight: 500;
      width: 160px;
      text-align: center;
    }

    a.active {
      color: $white;
      border-bottom: 2px #6998ae solid;
    }
  }
}

.router-view-block {
  position: unset;
  margin-bottom: 18px;
  width: 70%;
  padding-right: 27px;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.activities-block {
  align-items: center;
}

.video-resources-block {
  width: 70%;
  padding-right: 26px;
  padding-bottom: 16px;
}

.tooltip-wrapper {
  position: absolute;
  top: -18px;
  margin-left: 50%;
  transform: translateX(-50%);
  .study-tools-tooltip {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 2px 10px;
    background: #b56941;
    border-radius: 0 0 5px 5px;
    color: #ffffff;
  }
}

.router-view-block__store-items {
  width: 100%;
}
