@import "app/frontend/stylesheets/_user-variables.scss";

.bg-blur {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #323232;
  opacity: 0.9;
  z-index: 9999;
}

.authorization-modal-block {
  position: fixed;
  display: flex;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 10000;
  flex-direction: column;
  width: 462px;
  min-height: 295px;
  border-radius: 2px 2px 8px 8px;
  border-top: 4px solid #d18f42;
  background: #32303c;
  padding: 8px;

  .close-icon {
    width: 24px;
    height: 24px;
    display: flex;
    margin-left: auto;
  }

  .title {
    font-family: DM Sans, serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
    margin: 16px 40px 24px 40px;
  }

  .login-btn {
    width: 260px;
    height: 56px;
    padding: 13px 0 13px 0;
    border-radius: 8px 0 8px 0;
    gap: 10px;
    background-color: #6998ae;
    font-family: DM Sans, serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
    margin: 0 auto;
    cursor: pointer;
  }

  .sign-up {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    gap: 4px;

    .text {
      font-family: DM Sans, serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
      color: #ffffff;
    }

    a {
      font-family: DM Sans, serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
    }
  }
}
