@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';
.message-wrapper {
  max-width: 667px;
}
.message-date-label {
  font-size: 14px;
  color: #5c5c5c;
  text-align: center;
  font-weight: 500;
  margin: 8px 0;
}
