@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../shared/assets/styles/documents';

.links__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 10px;
  width: 100%;

  &:first-child {
    padding-top: 16px;
  }
}

.links__empty {
  width: 495px;
  height: 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
