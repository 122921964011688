@import "app/frontend/stylesheets/_user-variables.scss";

.videos-tab {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media (max-width: 463.98px) {
    justify-content: center;
  }
}
