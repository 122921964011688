@import "app/frontend/stylesheets/_user-variables.scss";

.link-preview {
  display: flex;
  justify-content: space-between;
  border-left: 1px solid #d18f42;
  padding-left: 12px;
  cursor: pointer;
}

.link-preview img {
  width: 148px;
  height: auto;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
}

.content {
  display: flex;
  flex-direction: column;
  width: 385px;

  .website {
    color: var(--primary-steel-blue-hover, #598aa0);
    font-family: DM Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .title {
    margin-top: 2px;
    color: var(--text-secondary, #5c5c5c);
    font-family: DM Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .description {
    margin-top: 4px;
    color: var(--text-secondary, #5c5c5c);
    font-family: DM Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.pulse {
  $from: #f5f5f5;
  $to: scale-color($from, $lightness: -10%);

  height: 16px;
  width: 100%;
  background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0 0;
    }
    .100% {
      background-position: -135% 0%;
    }
  }
}

.fake-img {
  width: 148px;
  height: auto;
  margin-right: 10px;
  border-radius: 4px;
}
