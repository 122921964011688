@import "app/frontend/stylesheets/_user-variables.scss";

.highlight {
  background-color: rgba(202, 164, 137, 0.5);
  border-radius: 4px;
  transition-duration: 300ms;
}
.transcript-preview__row {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-wrap: nowrap;
  padding-top: 15px;

  &.light {
    &:last-child {
      min-height: 136px;
      margin-bottom: 320px;
    }
  }

  &.dark {
    &:last-child {
      min-height: 136px;
      margin-bottom: 650px;
    }
  }
}

.transcript-preview__timestamp {
  cursor: pointer;
  width: 90px;
  height: 20px;
  border-radius: 25px;
  color: #5c5c5c;
  border: 1px solid #d1d3d4;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  line-height: 20px;

  &.dark {
    color: #D1D3D4;

    border: 1px solid #444050;
  }
}

.transcript-preview__row-text {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: auto;
  padding: 1px 3px;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    background-color: rgba(202, 164, 137, 0.35);
    border-radius: 4px;
    transition-duration: 300ms;
  }

  &:first-child {
    margin-left: -3px;
  }
}
