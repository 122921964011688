@import "app/frontend/stylesheets/_user-variables.scss";

.banner-form {
  width: 500px;
  padding: 10px 40px 10px;
}

.banner-info {
  .banner-name {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
}
.actions {
  text-align: right;
  padding-right: 10px;
}
.field {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
  .field-name {
    width: 150px;
    text-align: right;
    margin-right: 10px;
  }
  input {
    max-width: 250px;
  }
}
