@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../shared/assets/styles/video_resources';

.image-block {
  display: flex;
  flex: 0 0 calc(20% - 14px);
}

.image__form-control {
  display: flex;
  width: 517px;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 6px;
}

.image__form-button {
  padding-top: 8px;
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

.image__button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5c5c5c;
  padding: 3px 50px;

  &:disabled {
    background-color: #f1f3f4;
  }

  &:hover {
    background-color: #fafffa;
  }
}

.image__image {
  padding-bottom: 8px;
}

.image__delete {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.image__delete-icon {
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Ccircle cx='12' cy='12' r='12' fill='black' fill-opacity='0.6'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.0651 7.80995C17.3254 7.5496 17.3254 7.12749 17.0651 6.86714C16.8047 6.60679 16.3826 6.60679 16.1222 6.86714L11.966 11.0233L7.80995 6.86724C7.5496 6.60689 7.12749 6.60689 6.86714 6.86724C6.60679 7.12759 6.60679 7.5497 6.86714 7.81005L11.0232 11.9661L6.86852 16.1209C6.60817 16.3812 6.60817 16.8033 6.86852 17.0637C7.12887 17.324 7.55098 17.324 7.81133 17.0637L11.966 12.909L16.1209 17.0638C16.3812 17.3241 16.8033 17.3241 17.0637 17.0638C17.324 16.8034 17.324 16.3813 17.0637 16.121L12.9089 11.9661L17.0651 7.80995Z' fill='white'/%3E%3C/svg%3E");
}

.image__file-error {
  position: absolute;
  bottom: -8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #d35163;
}

.image__container {
  position: relative;
  color: #323232;
  cursor: pointer;

  &.active,
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .image__delete {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.image__thumbnail {
  object-fit: cover;
  aspect-ratio: 1;
  width: 100%;
  border-radius: 4px;
}
.image-loader {
  display: none;
}
@media (max-width: 1030px) {
  .image__container {
    width: 130px;
    height: 130px;
  }
  .image__thumbnail {
    width: 130px;
    height: 130px;
  }
}
@media (max-width: 991px) {
  .image__container {
    width: calc(33vw - 10px);
    height: calc(33vw - 10px);
  }
  .image__thumbnail-container {
    width: calc(33vw - 10px);
    height: calc(33vw - 10px);
    position: relative;
  }
  .image__thumbnail {
    width: calc(33vw - 10px);
    height: calc(33vw - 10px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
  .image-loader {
    display: block;
    width: calc(33vw - 10px);
    height: calc(33vw - 10px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
