@import "app/frontend/stylesheets/_user-variables.scss";

.bookmark-back-button {
  background: #32303C;
  box-shadow: 0px 4px 30px rgba(23, 24, 28, 0.6);
  border-radius: 100px;
  padding: 14px 16px;
  cursor: pointer;
  height: 56px;
}
