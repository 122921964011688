@import "app/frontend/stylesheets/_user-variables.scss";

.bg-blur {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #323232;
  opacity: 0.9;
  z-index: 9999;
}

.comment-report-block {
  position: fixed;
  display: flex;
  top: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 10000;
  flex-direction: column;
  width: 385px;
  min-height: 295px;
  background: #32303c;
  border-radius: 8px;
  padding: 16px 16px 32px 32px;

  .close-icon {
    width: 24px;
    height: 24px;
    display: flex;
    margin-left: auto;
  }

  .title {
    font-family: DM Sans, serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-align: left;
    color: #ffffff;
    margin-bottom: 22px;
  }
}

.reason-radio {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-family: DM Sans, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #ffffff;
  margin-bottom: 20px;
  padding-top: 2px;

  &:first-letter {
    text-transform: capitalize;
  }
}

.reason-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.reason-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #706c7a;
  border-radius: 50%;
}

.reason-radio input:checked ~ .checkmark {
  border: 1px solid #6998ae;
}

.reason-radio .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.reason-radio input:checked ~ .checkmark:after {
  display: block;
}

.reason-radio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #6998ae;
}

.actions-block {
  margin-top: 4px;
  margin-left: auto;
  gap: 8px;
  margin-right: 24px;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 32px;
    border-radius: 4px;
    font-family: DM Sans, serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
  }

  .cancel-btn {
    background-color: #444050;
    color: #ffffff;
  }

  .report-btn {
    background-color: #6998ae;
    color: #ffffff;

    &:disabled {
      color: #8f8a9b;
      background-color: #3b3946;
    }
  }
}
