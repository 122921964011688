@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';

h2.title {
  font-weight: 500;
  line-height: 1.6;
  display: flex;
  align-items: center;

  .title-text {
    font-size: 20px;
    color: $white;
  }
}
.recent-activities-container {
  background-color: #2b2834;
  border-radius: 8px;
  margin-bottom: 30px;
  height: 300px;
}

.recent-activities-placeholder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.child {
  font-size: 16px;
  font-weight: 400;
  color: #8f8a9b;
}
