@import "app/frontend/stylesheets/_user-variables.scss";

.main-block-wrapper {
  position: relative;
  width: 100%;
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 32px;
}

.main-block {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.store-items__sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 17%;
  padding-right: 16px;
  gap: 8px;
}

.store-items__container {
  width: 83%;
  display: flex;
  flex-direction: column;
  padding-top: 28px;

  .store-items__container-title {
    font-family: DM Sans, serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: #323232;
    margin-bottom: 16px;
  }
}

.store-items__block {
  max-width: 959px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 20px;

  .container {
    padding-left: 22px;
    padding-top: 11px;
  }
}

.store-items__sub-title {
  font-family: DM Sans, serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #5c5c5c;
}

.store-items__buttons-block {
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.store-items__button {
  height: 32px;
  top: 46px;
  left: 22px;
  padding: 6px 12px 6px 12px;
  gap: 24px;
  border-radius: 4px;
  background-color: #323232;
  color: #ffffff;
  font-family: DM Sans, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}

.store-items__button-disabled {
  background-color: #a6a6a6;
  cursor: not-allowed;
}

.store-items__button-white {
  color: #323232;
  background-color: #ffffff;
  border: 1px solid #d1d3d4;
  box-shadow: 0 1px 4px 0 #0000001a;
}

.store-items__tab {
  display: flex;

  a {
    padding: 6px 12px;
    border-radius: 3px;
    color: #323232;
    text-decoration: none;

    &.active {
      background: #414042;
      color: #ffffff;
    }
  }
}

.store-items__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #323232;
}

.store-items__search-block {
  border-top: 1px solid #eaedee;
  margin-bottom: 16px;

  .title {
    font-family: DM Sans, serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #5c5c5c;
  }

  .loader {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.store-items__search-input {
  margin-top: 8px;
  width: 517px;
  height: 32px;
  top: 145px;
  left: 22px;
  border-radius: 2px;
  padding: 12px 16px 12px 16px;
  border: 1px solid #d1d3d4;
  font-family: DM Sans, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  &:focus-visible {
    outline: none;
  }

  margin-bottom: 12px;
}

.store-items__search-actions-block {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 12px;
}

.assigned-store-items-block {
  margin-bottom: 8px;

  .title {
    font-family: DM Sans, serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: #323232;
  }
}
