@import "app/frontend/stylesheets/_user-variables.scss";

.link__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 53px;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  justify-content: center;
  padding-left: 24px;
}

.link__with-description:hover {
  cursor: pointer;
  background: #32303c;
}

.link__disabled {
  pointer-events: none;
}

.link__link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 520px;
}

.link__title {
  width: 520px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .link__container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .link__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .link__link {
    max-width: 100%;
  }
}
