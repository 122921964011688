@import "app/frontend/stylesheets/_user-variables.scss";

.store-items-resource {
  .info-block {
    display: none !important;
  }
}

.store-items-resource__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 16px 0;
  color: #ffffff;
  font-size: 20px;
}

.store-items-resource__link {
  font-size: 16px;
  cursor: pointer;
}
