@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../shared/assets/styles/global';

.tags-select__dropdown {
  position: absolute;
  width: auto;
  min-width: 276px;
  max-width: 500px;
  overflow: visible;
  z-index: 1;
}

.create-new-tag {
  padding: 8px 16px;
}

.recommended-tags,
.create-new-tag {
  border-radius: 2px;
  box-shadow: 0 4px 30px rgba(23, 24, 28, 0.6);
}

.tags-name-length,
.create-tag {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.tags-name-length {
  font-size: 12px;
}

.create-tag {
  padding: 9px 16px;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  width: 100%;
}

.tags-select__wrapper {
  width: auto;
  position: relative;
  min-width: 220px;
}

.tags-select__input {
  border: none;
  margin-left: 4px;
  width: 100%;
  height: 36px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #a6a6a6;
    font-size: 14px;
    line-height: 18px;
  }
}

.recommended-tag {
  font-size: 14px;
  padding: 8px 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// theme

$text-color-dark: $white;
$hovered-tag-dark: #706c7a;
$tags-name-length-dark: #d1d3d4;
$tags-select__dropdown-dark: #444050;
$create-tag-background-dark: #6998ae;
$create-tag-color-dark: $white;

$text-color-light: #5c5c5c;
$hovered-tag-light: #f9f9f9;
$tags-name-length-light: #5c5c5c;
$tags-select__dropdown-light: $white;
$create-tag-background-light: #6998ae;
$create-tag-color-light: $white;

.light {
  .create-new-tag,
  .tags-select__dropdown {
    background-color: $tags-select__dropdown-light;
  }

  .tags-name-length {
    color: $tags-name-length-light;
  }

  .create-tag {
    color: $create-tag-color-light;
    background-color: $create-tag-background-light;
    box-shadow: 0 2px 10px rgba(73, 73, 73, 0.1);
  }

  .tags-select__input {
    color: $text-color-light;
    background: transparent;
  }

  .recommended-tag {
    color: $text-color-light;

    &:hover {
      background-color: $hovered-tag-light;
      cursor: default;
    }
  }
}

.dark {
  .create-new-tag,
  .tags-select__dropdown {
    background-color: $tags-select__dropdown-dark;
  }

  .tags-name-length {
    color: $tags-name-length-dark;
  }

  .create-tag {
    color: $create-tag-color-dark;
    background-color: $create-tag-background-dark;
    box-shadow: 0 2px 10px rgba(73, 73, 73, 0.1);
  }

  .tags-select__input {
    color: $text-color-dark;
    background: transparent;
  }

  .recommended-tag {
    color: $text-color-dark;

    &:hover {
      background-color: $hovered-tag-dark;
      cursor: default;
    }
  }
}
