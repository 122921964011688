@import "app/frontend/stylesheets/_user-variables.scss";

@import '../shared/assets/styles/global';
.message-app-wrapper {
  height: 83vh;
  overflow: scroll;
}
.message-loader img {
  width: 42px;
  margin-left: 0;
}
