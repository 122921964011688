@import "app/frontend/stylesheets/_user-variables.scss";

.video-resources__sidebar {
  width: 167px;
  padding-top: 15px;
}

.video-resources__main {
  padding: 0;
  background: #2b2834;
  border-radius: 8px;
  min-height: 309px;
  max-height: 400px;
  display: flex;
  flex-direction: row;
}

.video-resources__container.guides,
.video-resources__container.documents,
.video-resources__container.transcripts {
  padding: 0;
}
.video-resources__container.images {
  width: 92%;
  padding: 0;
}
.video-resources__container {
  padding: 0 24px;
  border-left: 1px solid #3b3946;
  width: 100%;
  min-width: 487px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;

    &:hover {
      width: 10px;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8f8a9b;
    border: 4px solid #8f8a9b;
  }
}

.video-resources__tab a {
  display: flex;
  border-radius: 0 3px 3px 0;
  color: #ffffff;
  text-decoration: none;
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 24px;
  font-weight: 500;
  font-size: 14px;

  &.router-link-active {
    background: #6998ae;
    color: #ffffff;
  }
}

.video-resources__title {
  margin: 16px 0;
  color: #ffffff;
  font-size: 20px;
}

.bookmarks-loader {
  margin: 50px auto;
  width: 200px;
  display: flex;
  vertical-align: middle;
}

.bookmark-back-button__container {
  position: relative;
  width: 100%;

  .bookmark-back-button {
    position: absolute;
    right: -28px;
    top: -98px;
  }
}

@media (max-width: 991px) {
  .back-button__container {
    display: none;
  }
  .video-resources__container {
    border: none;
    min-width: unset;
    overflow-y: unset;
  }
  .video-resources__container.images {
    width: 100%;
  }
  .video-resources__container.ebooks {
    padding: 0 10px;
  }

  .video-resources__main {
    flex-direction: column;
    max-height: calc(100% - 60px);
    height: unset;
    padding-bottom: 20px;
    border-radius: 0;
    background: #1c1923;
  }
  .video-resources__sidebar {
    width: 100%;
    padding: 0 14px;
    white-space: nowrap;
    border-bottom: 1px solid #2b2834;
    margin-bottom: 6px;
  }
  .video-resources__sidebar-wrapper {
    overflow-x: scroll;
    flex-shrink: 0;
  }
  .video-resources__sidebar-wrapper::-webkit-scrollbar {
    display: none;
  }
  .video-resources__sidebar-wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .video-resources__tab {
    display: inline-block;
    a {
      color: #8f8a9b;
      padding: 0 16px;
      border-radius: 0;
      &.router-link-active {
        background: none;
        color: #fff;
        position: relative;
        //border-bottom: 2px solid #6998ae;
        margin-bottom: 1px;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 2px;
          background: #6998ae;
        }
      }
    }
  }
  .video-resources__title {
    display: none;
  }
}

.links {
  padding: 0;
}
