@import "app/frontend/stylesheets/_user-variables.scss";

.recent-search-block {
  display: flex;
  width: 688px;
  flex-direction: column;
  margin: 0 auto;

  @media (max-width: 991.98px) {
    margin: 0 32px 0 42px;
  }
}

.recent-search__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  margin-left: 30px;

  @media (max-width: 991.98px) {
    margin-left: 0;
  }

  .title {
    font-family: DM Sans, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: #fff;

    @media (max-width: 991.98px) {
      font-size: 16px;
    }
  }

  .title__light {
    color: #323232;
  }

  .clean-all {
    font-family: DM Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #8f8a9b;
    cursor: pointer;
    margin-right: 3px;

    @media (max-width: 991.98px) {
      font-size: 14px;
    }
  }
}

.recent-search__list {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  width: 100%;
  max-height: 220px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;

    &:hover {
      width: 10px;
    }
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #8f8a9b;
    border-radius: 4px;
    border: 4px solid #8f8a9b;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }

  @media (max-width: 991.98px) {
    margin-left: 0;
  }

  margin-bottom: 56px;
}

.recent-search-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0 auto 56px;
}
