@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';

.thumbnail-container {
  width: 180px;
  height: 210px;
  position: relative;

  .thumbnail {
    object-fit: cover;
    font-size: 16px;
  }

  .logo-thumbnail {
    width: auto;
    height: 35px;
    object-fit: contain;
    object-position: top left;
    font-size: 14px;
  }

  img {
    width: 180px;
    height: 90px;
  }
}

.text-content {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 180px;
  max-height: 54px;
  margin-top: 6px;
  font-size: 14px;
  line-height: 18px;
  color: #323232;
}

.footnote {
  line-height: 14px;
  margin-top: 3px;
  width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  a {
    color: #5c5c5c;
    font-size: 14px;
    text-decoration: none;
  }
}
