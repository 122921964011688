@import "app/frontend/stylesheets/_user-variables.scss";

.breadcrumbs_container .breadcumbs {
  padding: 0;
  display: flex;
}
.breadcumb-wrapper {
  color: #a6a6a6;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  &:not(:first-of-type) {
    &:before {
      content: '/';
      margin: 0 4px;
      color: #A6A6A6;
      font-size: 13px;
    }
  }
}
.breadcrumbs_container .breadcumbs {
  a {
    color: #a6a6a6;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    &.router-link-active {
      color: #323232;
    }
  }
}
