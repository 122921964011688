.document__form-buttons {
  padding-top: 8px;
}

.document__document {
  padding-bottom: 8px;
}
.document__delete {
  position: absolute;
  right: -42px;
  top: 5px;
  padding: 12px 0;
  cursor: pointer;
}

.document__delete-icon {
  width: 19px;
  height: 2px;
}

.document__description {
  margin-top: 16px;
  width: 320px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.document__preview-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 10px;
}

.video-resources_download {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 10px;
  border: 1px dotted #444050;
  border-radius: 8px;
  margin-left: auto;
  svg {
    height: 16px;
    width: 16px;
  }
  .video-resources__filesize {
    line-height: 16px;
    font-size: 10px;
    min-width: 40px;
    max-width: 50px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.document__file-error {
  position: absolute;
  top: 70px;
}

.document__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 50%;
  height: 59px;
  padding: 0 16px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  @media (max-width: 768px) {
    width: 100%;
  }

  .document__delete {
    position: relative;
    top: 0;
    right: 0;
    margin-left: auto;
  }
}

.document__text {
  width: 65%;
  overflow: hidden;
  padding: 0 16px;
  max-height: 55px;
}
.document__text__wrapped {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.document__control-spacer {
  flex: 1;
}

// THEME

.dark {
  .document__form-input {
    color: #fff;

    &::placeholder {
      color: #a6a6a6;
    }
  }

  .document__counter {
    color: #a6a6a6;
  }

  .video-resources__filesize {
    color: #fff;
  }

  .document__delete-icon {
    background: #5c5c5c;
  }

  .document__container {
    border-right: 1px solid #3b3946;
    border-bottom: 1px solid #3b3946;
    color: #fff;

    &:hover {
      background-color: #32303c;
    }

    &.active {
      background-color: #32303c;
    }
  }
}

.light {
  .document__form-input {
    color: #323232;

    &::placeholder {
      color: #a6a6a6;
    }
  }

  .document__counter {
    color: #a6a6a6;
  }

  .document__delete-icon {
    background: #5c5c5c;
  }

  .document__container {
    border-right: 1px solid #d1d3d4;
    border-bottom: 1px solid #d1d3d4;
    color: #323232;

    &:hover {
      background-color: #f1f3f4;
    }

    &.active {
      background-color: #f1f3f4;
    }
  }
}

@media (max-width: 991px) {
  .dark {
    .document__container {
      border: none;
      border-bottom: 1px solid #3b3946;
      background-color: #1c1923;
    }
  }
}

.document__preview-button {
  margin-left: auto;
}