@import "app/frontend/stylesheets/_user-variables.scss";

.back-button__container {
  position: relative;
  width: 100%;

  .bookmark-back-button {
    position: absolute;
    right: -28px;
    top: -98px;
  }
}
