@import "app/frontend/stylesheets/_user-variables.scss";

.program__block {
  position: relative;
  height: 127px;
  border-bottom: solid #eaedee 1px;

  @media (max-width: 768px) {
    height: 149px;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
}

.program__active {
  border: 1px solid #d18f42 !important;
  border-radius: 8px;
  width: 101.2%;
  right: 6px;

  @media (max-width: 768px) {
    width: 100%;
    right: 0;
  }
}

.program__time {
  position: absolute;
  width: 143px;
  height: 100%;
  padding-top: 16px;
  padding-left: 20px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #323232;
  border-right: solid #eaedee 1px;

  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 0;
    padding-left: 0;
    border-right: none;
  }
}

.program__timeunit {
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #5c5c5c;

  @media (max-width: 768px) {
    margin-left: 6px;
  }
}

.program__time-active {
  width: 148px;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
  }
}

.program__description {
  position: relative;
  top: 16px;
  left: 168px;
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  @media (max-width: 768px) {
    top: 0;
    left: 0;
  }
}

.program__title {
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #323232;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.program__series {
  margin-top: 4px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #323232;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.program__speaker {
  margin-top: 4px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #5c5c5c;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.program__time-label {
  position: absolute;
  width: 67px;
  height: 24px;
  left: 30px;
  padding-top: 4px;
  background: #d18f42;
  border-radius: 4px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: $white;

  @media (max-width: 768px) {
    position: relative;
    left: 0;
    margin-left: auto;
  }
}
