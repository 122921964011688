@import "variables";

* {
  font-family: $font-family;
}

.study-tools-title-area {
  height: 64px;
  margin-top: auto;
  margin-bottom: auto;
}
