@import "app/frontend/stylesheets/_user-variables.scss";

.bg-blur {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #323232;
  opacity: 0.9;
  z-index: 9999;
}

.description-modal-block {
  position: fixed;
  display: flex;
  top: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 10000;
  flex-direction: column;
  width: 462px;
  min-height: 459px;
  border-radius: 8px;
  background: var(--grayscale-strong-grey, #32303c);
  padding: 40px 48px 40px 48px;

  .name {
    display: flex;
    width: 100%;
    margin-bottom: 12px;
    color: #fff;
    font-family: DM Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .link__title {
    display: flex;
    width: 100%;
    color: #fff;
    font-family: DM Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .link__href {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
    color: #6998ae;
    font-family: DM Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .link__description {
    display: flex;
    width: 100%;
    margin-bottom: 32px;
    color: var(--backgrounds-white, #fff);
    font-family: DM Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .link__btn {
    display: flex;
    width: 123px;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: auto;
    background: var(--primary-steel-blue-default, #6998ae);
    box-shadow: 0 1px 10px 0 rgba(29, 32, 35, 0.15);
    cursor: pointer;
    color: var(--backgrounds-white, #fff);
    font-family: DM Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .modal__close {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
