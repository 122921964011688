@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';

.news-content__container {
  transition-duration: 300ms;
  background-color: inherit;

  :global(.highlight) {
    background-color: #dae4f3;
    border-radius: 4px;
    transition-duration: 300ms;
  }
}

.news-content__tooltip {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.news-content__tooltip-buttons-container {
  display: flex;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  width: 122px;
  height: 32px;
  border-radius: 4px;
  background-color: #6998ae;
  color: #ffffff;

  &:after {
    position: absolute;
    border: 11px solid transparent;
    border-top-color: #6998ae;
    top: 32px;
    left: 50px;
    content: '';
    display: block;
    width: 0;
    height: 0;
  }
}

.news-content__container {
  position: relative;
  width: 100%;
}

.news-content__icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  opacity: 1;

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.news-content__bookmark-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5H4.71429C4.25963 5 3.82359 5.1873 3.5021 5.5207C3.18061 5.8541 3 6.30628 3 6.77778V21L9 16.5556L15 21V12.1111' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.5 3.375C17.141 3.375 16.85 3.66601 16.85 4.025V7.04208H14.025C13.666 7.04208 13.375 7.33309 13.375 7.69208C13.375 8.05106 13.666 8.34208 14.025 8.34208H16.85V10.975C16.85 11.334 17.141 11.625 17.5 11.625C17.859 11.625 18.15 11.334 18.15 10.975V8.34208H20.975C21.334 8.34208 21.625 8.05106 21.625 7.69208C21.625 7.3331 21.334 7.04208 20.975 7.04208H18.15V4.025C18.15 3.66601 17.859 3.375 17.5 3.375Z' fill='white'/%3E%3C/svg%3E%0A");
}

.news-content__copy-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='7.5' y='7.5' width='14' height='14' rx='2' stroke='white' stroke-width='2'/%3E%3Cpath d='M16.5 5V4.5C16.5 3.39543 15.6046 2.5 14.5 2.5H4.5C3.39543 2.5 2.5 3.39543 2.5 4.5V14.5C2.5 15.6046 3.39543 16.5 4.5 16.5H5' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}

.news-content__facebook-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.7946 13.0012L16.2475 10.1033H13.4156V8.22277C13.4156 7.42996 13.8112 6.65717 15.0795 6.65717H16.3669V4.18992C16.3669 4.18992 15.1987 3.99414 14.0816 3.99414C11.7495 3.99414 10.2251 5.38211 10.2251 7.8947V10.1033H7.63281V13.0012H10.2251V20.0067H13.4156V13.0012H15.7946Z' fill='white'/%3E%3C/svg%3E%0A");
}

.news-content__x-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' width='20px' height='20px' viewBox='0 0 488.000000 459.000000' preserveAspectRatio='xMidYMid meet'%3E%3Cg transform='translate(0.000000,459.000000) scale(0.100000,-0.100000)' fill='%23FFFFFF' stroke='none'%3E%3Cpath d='M14 4579 c-2 -3 160 -224 359 -490 199 -266 622 -832 940 -1257 l578 -773 -37 -42 c-21 -23 -446 -482 -944 -1020 -498 -539 -907 -983 -908 -988 -2 -5 85 -9 210 -8 l213 1 70 77 c39 42 401 433 805 870 404 437 746 807 760 823 l25 29 200 -268 c110 -147 413 -553 673 -901 l474 -632 725 0 c603 0 724 2 720 13 -3 8 -187 256 -409 553 -222 296 -476 636 -564 754 -89 118 -350 469 -582 778 -232 310 -422 568 -422 572 0 7 1226 1338 1643 1783 l119 127 -219 0 -218 -1 -255 -276 c-140 -152 -483 -524 -763 -825 l-507 -548 -615 822 -616 823 -725 5 c-399 3 -728 2 -730 -1z m1718 -886 c237 -318 603 -807 813 -1088 210 -280 676 -904 1036 -1385 360 -482 658 -881 663 -888 6 -9 -65 -12 -324 -12 l-331 0 -656 878 c-1514 2023 -2278 3045 -2287 3060 -6 9 62 12 323 12 l331 0 432 -577z'/%3E%3C/g%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
}

.news-content__linkedin-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.15769 18.4176V9.33841H5.13996V18.4176H8.15769ZM6.64922 8.09806C7.70155 8.09806 8.35658 7.40088 8.35658 6.52965C8.33698 5.63876 7.7016 4.96094 6.66919 4.96094C5.63694 4.96094 4.96191 5.63878 4.96191 6.52965C4.96191 7.40092 5.61678 8.09806 6.62952 8.09806H6.64912H6.64922ZM9.828 18.4176H12.8457V13.3473C12.8457 13.076 12.8653 12.8049 12.945 12.6109C13.1632 12.0688 13.6597 11.5073 14.4934 11.5073C15.5854 11.5073 16.0222 12.3399 16.0222 13.5604V18.4175H19.0398V13.2116C19.0398 10.4228 17.551 9.12526 15.5655 9.12526C13.9375 9.12526 13.2228 10.0352 12.8257 10.655H12.8458V9.33823H9.82807C9.86767 10.1902 9.82807 18.4174 9.82807 18.4174L9.828 18.4176Z' fill='white'/%3E%3C/svg%3E%0A");
}

.news-content__copy-alert {
  position: absolute;
  top: 30px;
  left: 80px;
  display: inline-flex;
  padding: 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(38, 42, 46, 0.3);
}

.modal-b {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 25, 35, 0.8);
}

.modal-c {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 15% auto;
  padding: 32px;
  width: 917px;
  min-height: 267px;
  background: $white;
  box-shadow: 0 4px 30px rgba(23, 24, 28, 0.6);
  border-radius: 8px;

  &.authorization {
    padding: 0;
    width: 367px;
    height: 270px;
  }
}

.authorization__wrapper {
  border-radius: 4px;
  border-top: 4px solid #d18f42;
  padding: 20px;
  display: block;
  font-size: 14px;
  line-height: 20px;
  background: #f3f4f5;
}

.modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.5878 5.70711C19.9783 5.31658 19.9783 4.68342 19.5878 4.29289C19.1973 3.90237 18.5641 3.90237 18.1736 4.29289L11.9403 10.5262L5.70711 4.29295C5.31658 3.90243 4.68342 3.90243 4.29289 4.29295C3.90237 4.68348 3.90237 5.31664 4.29289 5.70717L10.5261 11.9404L4.29302 18.1735C3.90249 18.564 3.90249 19.1972 4.29302 19.5877C4.68354 19.9782 5.3167 19.9782 5.70723 19.5877L11.9403 13.3546L18.1735 19.5878C18.564 19.9783 19.1972 19.9783 19.5877 19.5878C19.9782 19.1972 19.9782 18.5641 19.5877 18.1735L13.3545 11.9404L19.5878 5.70711Z' fill='%23A6A6A6'/%3E%3C/svg%3E");
}
