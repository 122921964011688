@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../../shared/assets/styles/video_resources';

.text-input__form-input {
  font-size: 14px;
  line-height: 18px;
  color: #323232;
  width: 100%;

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a6a6a6;
  }
}

.text-input__counter {
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #a6a6a6;
  font-family: 'DM Sans';
  font-style: normal;
}

.text-input__form-control {
  display: flex;
  width: 517px;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 6px;
}
.text-input__error {
  position: absolute;
  top: 36px;
}
