@import "app/frontend/stylesheets/_user-variables.scss";

.store-items__input {
  width: 100%;
  height: 32px;
  top: 145px;
  left: 22px;
  border-radius: 2px;
  padding: 12px 16px 12px 16px;
  border: 1px solid #d1d3d4;
  font-family: DM Sans, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  &:focus-visible {
    outline: none;
  }

  margin-bottom: 6px;
}

.store-items__input-area {
  height: 111px;
  font-family: DM Sans, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #323232;
  resize: none;
}

.store-items__preview {
  max-width: 240px;
  max-height: 200px;
  border-radius: 2px;
  border: 1px solid #d1d3d4;

  img {
    width: 100%;
    height: 100%;
  }
}

.store-items__input-group {
  width: 520px;
}

.store-items__form {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.store-items__buttons-title {
  font-family: DM Sans, serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #5c5c5c;
}

.store-items__buttons-group {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 13px;
}

.counter {
  font-family: DM Sans, serif;
  font-size: 8px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  color: #a6a6a6;
  display: flex;
  justify-content: end;
}
