@import "app/frontend/stylesheets/_user-variables.scss";

.field {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
  .field-name {
    width: 150px;
    text-align: right;
    margin-right: 10px;
  }
  input, textarea {
    max-width: 250px;
  }
}
