@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';

.note-loader {
  margin: 50px auto;
  width: 200px;
  display: flex;
  vertical-align: middle;
}

.notes-list {
  margin-top: 28px;
}

.notes-list-container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding-inline-start: 0;
  height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;

    &:hover {
      width: 10px;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}
.notes-list-container.overflow-unset {
  overflow-y: unset;
  overflow-x: unset;
}
.note-actions {
  display: flex;
  margin-left: auto;
  justify-content: end;
  width: 205px;
  height: 30px;
  margin-right: 3px;
  align-items: center;
}

.delete-button-wrapper {
  margin-top: 16px;
  margin-right: 16px;
}

.locked {
  pointer-events: none;

  .locked-label-wrapper {
    display: block;
    position: relative;
    width: 0;
    .locked-label {
      position: absolute;
      width: 120px;
      left: 0;
      top: 10px;
      background: black;
      padding: 4px 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
.locked-label-wrapper {
  display: none;
}
.empty-list {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: auto;
}
.notes__note {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.notes__note-content-wrapper {
  width: 100%;
  padding: 16px;
}
.notes__note-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2px;
}
.notes__note-content {
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: unset;
}
.tags-area {
  margin-top: 18px;
  margin-right: 16px;
  position: relative;
}
.note-tags__title {
  font-size: 12px;
  line-height: 16px;
}

.tags-button {
  padding: 3px 12px;
  font-size: 14px;
  border-radius: 25px;
}

// theme
// dark
$edit-tags-color-light: #5c5c5c;
$tag-border-light: #d1d3d4;
$tags-limit-color-light: #8f8a9b;
$edit-tags-color-dark: #d1d3d4;
$tag-border-dark: #444050;
$tags-limit-color-dark: #8f8a9b;
$tags-white: #ffffff;
$tags-button-dark: #3b3946;
$note-hovered-dark: #32303c;

// light

$tag-border-light: #eaedee;

.light {
  .note-tags__tag-name {
    color: $edit-tags-color-light;
  }
}

.dark {
  &.locked {
    .locked-label-wrapper {
      .locked-label {
        color: $tags-white;
        background: #b56941;
      }
    }

    .notes__note-title,
    .notes__note-content,
    .note__action-button,
    .tags-button {
      color: $edit-tags-color-light;
    }
    .delete-button-wrapper {
      opacity: 0.3;
    }
  }
  .notes-list-container {
    background-color: #2b2834;
    &::-webkit-scrollbar-thumb {
      background-color: $tags-limit-color-dark;
      border: 4px solid $tags-limit-color-dark;
    }
  }

  .tags-button {
    background-color: #3b3946;
    border: 1px solid #444050;
    color: #ffffff;
  }

  .notes__note {
    border-bottom: 1px solid $tag-border-dark;
    &:hover {
      background: $note-hovered-dark;
      cursor: pointer;
    }
  }
  .notes__note-title,
  .notes__note-content,
  .note__action-button {
    color: $tags-white;
  }
  .note__cancel-button {
    background-color: $tag-border-dark;
  }

  .note-tags__title {
    color: $tags-white;
  }

  .note-tags__tag-name {
    color: $edit-tags-color-dark;
  }

  .note-tags__tag {
    border: 1px solid $tag-border-dark;
  }
  .empty-list {
    color: $tags-limit-color-dark;
  }
}

.light {
  .notes__note {
    border-bottom: 1px solid $tag-border-light;

    &:hover {
      background: #f9f9f9;
    }
  }

  .tags-button {
    background-color: #ffffff;
    border: 1px solid #d1d3d4;
    color: #5c5c5c;
  }
  &.notes-list {
    margin-top: 0;
  }
  .notes-list-container {
    height: unset;

    &:hover {
      cursor: pointer;
    }
  }
}
