@import "app/frontend/stylesheets/_user-variables.scss";

.recent-search__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;

  .name {
    font-family: DM Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
    cursor: pointer;
    max-width: 660px;

    @media (max-width: 767.98px) {
      max-width: 475px;
    }

    @media (max-width: 575.98px) {
      max-width: 285px;
    }
  }

  .name__light {
    color: #5c5c5c;
  }

  .delete-item {
    cursor: pointer;

    @media (max-width: 991.98px) {
      width: 16px;
      height: 16px;
    }
  }

  .destroying-loader {
    cursor: not-allowed;
    width: 16px;
    height: 16px;
  }
}
