@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';
.no-messages {
  max-width: 630px;
  margin: 130px auto 0 auto;
  padding: 88px 96px;
  background-color: $white;
  box-shadow: 0 4px 40px rgba(167, 167, 167, 0.15);
  border-radius: 12px;
  border-top: 4px solid #d18f42;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  color: #323232;
}
