@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../shared/assets/styles/global';

.image-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.thumbnail-container {
  width: 180px;
  height: 90px;
  position: relative;

  .thumbnail {
    object-fit: cover;
  }

  .progress-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .duration {
    position: absolute;
    width: 69px;
    height: 24px;
    bottom: 0;
    right: 0;
    background-color: #1c1923;
    color: #ffffff;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
    font-weight: 500;
    opacity: 0.8;
  }
  .duration-with-offset {
    bottom: 4px;
  }

  img {
    width: 180px;
    height: 90px;
  }
}

.episode-container {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 16px;
  padding: 16px;
  position: relative;
}
.delete-button-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
}
.text-content {
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}

.title,
.series-title {
  max-width: 320px;
}

.title {
  a {
    font-size: 17px;
    line-height: 22px;
    color: #323232;
    display: block;
  }
}

.series-title {
  margin-top: 2px;
  font-size: 14px;
  color: #5c5c5c;
  display: block;
}

.speakers-short {
  display: flex;
  margin-top: 5px;

  .speaker-wrapper {
    height: 16px;
  }
  .speaker-avatar {
    margin-top: -16px;
    width: 16px !important;
    height: 16px !important;
    border: 0.5px solid #D1D3D4;
    border-radius: 50%;

    &:not(:first-child) {
      margin-left: -12px;
    }
  }

  .speaker-name {
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5C5C5C;
    margin-left: 6px;
  }
}
