@import "app/frontend/stylesheets/_user-variables.scss";

@import '../../../shared/assets/styles/global';
.tutorial-step {
  position: absolute;

  border: 1px solid #6998ae;
  box-shadow: 0 4px 30px rgba(23, 24, 28, 0.6);
  background: #2b2834;

  border-radius: 6px;
  padding: 16px 12px 20px 16px;

  z-index: 999;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 19px;
    right: 0;
    top: -28px;
    width: 0;
    height: 0;
    border: 14px solid transparent;
    border-bottom-color: #6998ae;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 19px;
    right: 0;
    top: -27px;
    width: 0;
    height: 0;
    border: 14px solid transparent;
    border-bottom-color: #2b2834;
  }
}

.tutorial-step[data-step='1'] {
  width: 306px;
}

.tutorial-step[data-step='6'] {
  &::before {
    left: -28px;
    top: 31px;
    border: 14px solid transparent;
    border-right-color: #6998ae;
  }

  &:after {
    left: -27px;
    top: 31px;
    border: 14px solid transparent;
    border-right-color: #2b2834;
  }
}

.tutorial-step[data-step='7'],
.tutorial-step[data-step='10'] {
  right: 8px;

  &::before {
    left: 249px;
  }

  &:after {
    left: 249px;
  }
}

.tutorial-step[data-step='8'] {
  right: 8px;

  &::before {
    left: 236px;
  }

  &:after {
    left: 236px;
  }
}

.tutorial-step[data-step='15'],
.tutorial-step[data-step='18'],
.tutorial-step[data-step='20'],
.tutorial-step[data-step='21'] {
  &::before {
    left: -28px;
    top: 125px;
    border: 14px solid transparent;
    border-right-color: #6998ae;
  }

  &:after {
    left: -27px;
    top: 125px;
    border: 14px solid transparent;
    border-right-color: #2b2834;
  }
}
.tutorial-step[data-step='18'] {
  &::before {
    top: 10px;
  }

  &:after {
    top: 10px;
  }
}
.tutorial-step[data-step='20'],
.tutorial-step[data-step='21'] {
  &::before {
    top: 10px;
  }

  &:after {
    top: 10px;
  }
}
.tutorial-step[data-step='19'] {
  &::before {
    left: 245px;
  }

  &:after {
    left: 245px;
  }
}
.tutorial-step[data-step='11'],
.tutorial-step[data-step='16'],
.tutorial-step[data-step='17'] {
  &::before {
    left: 10px;
    top: 247px;
    border: 14px solid transparent;
    border-right-color: #6998ae;
    transform: rotate(-90deg);
  }

  &:after {
    left: 10px;
    top: 246px;
    border: 14px solid transparent;
    border-right-color: #2b2834;
    transform: rotate(-90deg);
  }
}
.tutorial-step[data-step='11'] {
  &::before {
    left: 15px;
    top: 127px;
  }

  &:after {
    left: 15px;
    top: 126px;
  }
}
.tutorial-step[data-step='17'] {
  &::before {
    top: 147px;
  }

  &:after {
    top: 146px;
  }
}

.tutorial-title {
  color: $white;
}

.tutorial-actions {
  display: flex;
  justify-content: left;
  margin-top: 16px;
}

.tutorial-action {
  padding: 7px 16px;
  background: #444050;
  box-shadow: 0 2px 10px rgba(73, 73, 73, 0.1);
  border-radius: 4px;
  margin-right: 8px;
  font-size: 14px;
  &.active {
    color: $white;
    cursor: pointer;
  }
  &.inactive {
    color: #8f8a9b;
    background: #444050;
    cursor: not-allowed;
  }
  &.blue-action {
    cursor: pointer;
    background: #6998ae;
    color: $white;
  }
}
