@import "app/frontend/stylesheets/_user-variables.scss";

.like-block {
  display: flex;
  flex-direction: row;
  background: #2b2834;
  min-width: 51px;
  padding: 4px 6px 4px 6px;
  width: auto;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #3b3946;
  }

  .count {
    font-family: DM Sans, serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
  }

  .active-icon {
    path {
      fill: #ffffff;
    }
  }
}

.like-gap {
  gap: 10px;
}

.like-tooltip {
  position: absolute;
  top: -18px;
  left: 3px;
  display: flex;
  align-items: center;
  background-color: #b56941;
  color: white;
  font-family: DM Sans, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  width: 44px;
  height: 18px;
  padding: 4px 10px 4px 10px;
  gap: 4px;
  border-radius: 5px 5px 0 0;
}
