@import '~video.js';
@import '~videojs-seek-buttons/dist/videojs-seek-buttons';
@import '~vendor/videojs-mobile-ui/video-mobile-ui';

@import 'application/positioning';

.video-js {
  .vjs-big-play-button {
    @include centered-absolute;
  }

  // Subtitles are displayed on mobile Safari despite they are hidden by default.
  // So we hide pop-up in which they are contained to prevent them appearing.
  .vjs-subs-caps-button {
    .vjs-menu {
      display: none !important;
    }
  }
}

// because by default we override all button icons to have `position: static` for nicer placement
.vjs-seek-button > .vjs-icon-placeholder::before {
  position: absolute !important;
}
